
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button, Spinner } from 'reactstrap';
import { connect } from "react-redux";
import { submitContactForm } from "../../actions/contactActions";
import Footer from "../layout/Footer";
import Helmet from 'react-helmet';
import withSizes from 'react-sizes'

import NavigationBarMobile from '../layout/NavigationBarMobile';
import NavigationBarDesktop from '../layout/NavigationBarDesktop';

import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import ReCAPTCHA from 'react-google-recaptcha';

const recaptchaRef = React.createRef();

class ContactPage extends Component {
    constructor() {
        super();

        this.renderForm = this.renderForm.bind(this)
        this.renderSuccess = this.renderSuccess.bind(this)
        this.renderFailure = this.renderFailure.bind(this)
        this.beforeSubmitValidation = this.beforeSubmitValidation.bind(this)

        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            subject: "",
            message: "",
            errors: {},

            formSubmitted: 0, // 0 = not yet submitted, 1 = successfully submitted, 2 = error while submitting
            recaptchaFailed: false
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    beforeSubmitValidation = e => {
        e.persist()
    }

    onSubmit = e => {

        e.preventDefault();

        const recaptchaValue = recaptchaRef.current.getValue();

        if (!recaptchaValue.length) {
            this.setState({ recaptchaFailed: true })
            return
        }

        const url = global.serverAddress + '/api/contact';

        const submission = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            subject: this.state.subject,
            message: this.state.message
        };


        const that = this

        this.props.submitContactForm(submission, function (result) {
            console.log(result)

            var value = (result === true) ? 1 : 2

            that.setState({
                formSubmitted: value
            })

        })

    };

    renderForm() {
        console.log("ContactPage.render called; value of this.props.progress: " + this.props.progress)

        const spinnerColor = this.props.progress > 0 ? '#0275d8' : '#ffffff00'
        const display = this.props.progress > 0 ? 'block' : 'none'

        return (
            <div className="container">

                {/* <div style={{ width: '40px', height: '40px', margin: 'auto' }}>
                    <Spinner style={this.props.progress > 0 ? { color: '#0275d8' } : { color: '#ffffff00' }} />
                </div> */}

                <div className="row">
                    <div className="col-lg-12">
                        <p className="customHeader" style={{ textAlign: 'center' }}>
                            Contact
                        </p>
                    </div>
                </div>

                <div style={{ height: "25px" }}></div>

                <div className="row">

                    <div className="col-lg-12">

                        <AvForm onValidSubmit={this.onSubmit} beforeSubmitValidation={this.beforeSubmitValidation} id="contactForm">

                            <div className="form-row">

                                <AvGroup className="form-group col-md-4" style={{ paddingBottom: '15px' }}>
                                    <label style={{ color: "white" }} htmlFor="firstName">First Name</label>

                                    <AvInput

                                        id="firstName"
                                        name="firstName"
                                        onChange={this.onChange}
                                        placeholder="First Name"
                                        required
                                        type="text"
                                        value={this.state.firstName}
                                        className="form-control"
                                    />
                                    <AvFeedback>First name is required.</AvFeedback>

                                </AvGroup>

                                <AvGroup className="form-group col-md-4" style={{ paddingBottom: '15px' }}>
                                    <label style={{ color: "white" }} htmlFor="lastName">Last Name</label>
                                    <AvInput
                                        id="lastName"
                                        name="lastName"
                                        onChange={this.onChange}
                                        placeholder="Last Name"
                                        required
                                        value={this.state.lastName}
                                        type="text"
                                        className="form-control"
                                    />
                                    <AvFeedback>Last name is required.</AvFeedback>
                                </AvGroup>

                                <AvGroup className="form-group col-md-4" style={{ paddingBottom: '15px' }}>
                                    <label style={{ color: "white" }} htmlFor="email">Email Address</label>
                                    <AvInput
                                        id="email"
                                        name="email"
                                        onChange={this.onChange}
                                        placeholder="first.last@example.com"
                                        required
                                        value={this.state.email}
                                        type="email"
                                        className="form-control"
                                    />
                                    <AvFeedback>A valid email is required.</AvFeedback>
                                </AvGroup>

                            </div>

                            <AvGroup className="form-group" style={{ paddingBottom: '15px' }}>
                                <label style={{ color: "white" }} htmlFor="subject">Subject</label>
                                <AvInput
                                    id="subject"
                                    name="subject"
                                    onChange={this.onChange}
                                    placeholder="Subject"
                                    required
                                    value={this.state.subject}
                                    type="text"
                                    className="form-control"
                                />
                                <AvFeedback>Subject is required.</AvFeedback>
                            </AvGroup>

                            <AvGroup className="form-group" style={{ paddingBottom: '15px' }}>
                                <label style={{ color: "white" }} htmlFor="message">Message</label>

                                <AvInput
                                    id="message"
                                    name="message"
                                    onChange={this.onChange}
                                    placeholder="Message"
                                    required
                                    value={this.state.message}
                                    type="textarea"
                                    style={{ height: '30vh' }}
                                />

                                <AvFeedback>Message is required.</AvFeedback>
                            </AvGroup>

                            <AvGroup className="float-right">
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey="6LeYlcwZAAAAADn5ash42rQUcDr7L3RAuSZzsJrm"
                                />
                                {this.state.recaptchaFailed ? <label htmlFor="recaptchaFailed" className="float-right" style={{ color: '#CA444A', fontSize: '13px' }}>Please be a human.</label> : <></>}
                            </AvGroup>

                            {/* <Button form="contactForm" className="float-right" color="primary">Submit</Button>
                            {this.state.recaptchaFailed ? <label htmlFor="recaptchaFailed" style={{ color: '#CA444A', fontSize: '13px' }}>Please be a human.</label> : <div></div>} */}

                        </AvForm>

                    </div>

                </div>

                <div className="row">
                    <div className="col-md-12">
                        <Button form="contactForm" className="float-right" color="primary">Submit</Button>
                        {/* {this.state.recaptchaFailed ? <label htmlFor="recaptchaFailed" className="float-right" style={{ color: '#CA444A', fontSize: '13px' }}>Please be a human.</label> : <div></div>} */}

                        {/* <div className="spinner-border float-right" style={{ width: '38px', height: '38px', color: '#952143', marginRight: '16px' }} role="status">
                            <span class="sr-only">Loading...</span>
                        </div> */}

                        {/* <div className="loader-wrapper float-right" style={ this.props.progress > 0 ? { display: 'block' } : { display: 'block' }}> */}
                        <div className="loader-wrapper" style={{ display: display }}>
                            <div className="loader-box float-right" style={{ marginTop: '1px', marginRight: '16px' }} >
                                <div className="loader">Loading...</div>
                            </div>
                        </div>

                    </div>
                </div>


                {/* <div className="row">
                    <div className="col-md-12">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="6LcHn7kZAAAAAInuIrAIFF1UqsS9Wc7GlekMD7kT"
                        />

                        <Button form="contactForm" className="float-right" color="primary">Submit</Button>
                        {this.state.recaptchaFailed ? <label htmlFor="recaptchaFailed" style={{ color: '#CA444A', fontSize: '13px' }}>Please be a human.</label> : <div></div>}

                    </div>
                </div> */}

                <Footer />
            </div>

        );
    }

    renderSuccess() {
        return (
            <div className="container">
                <div style={{ height: "64px" }}></div>
                <div className="row">
                    <div className="col-md-12">
                        <p className="customHeader" style={{ color: "white", fontSize: '20px', textAlign: 'center' }}>
                            Contact form submitted successfully.
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    renderFailure() {
        return (
            <div className="container">
                <div style={{ height: "64px" }}></div>
                <div className="row">
                    <div className="col-md-12">
                        <p className="customHeader" style={{ color: "white", fontSize: '20px', textAlign: 'center' }}>
                            Error submitting the contact form. Please <a href="http://localhost:3000/contact">try again.</a>
                        </p>
                    </div>
                </div>
            </div>
        )
    }


    render() {
        console.log("running ContactPage.render(); value of this.state.formSubmitted: " + this.state.formSubmitted)
        const { errors } = this.state;

        switch (this.state.formSubmitted) {
            case 0: {
                return (
                    <div>
                        <Helmet>
                            <title>{"Jake Whitesell – Contact"}</title>
                            <body className={"normal"} />
                        </Helmet>
                        {window.innerWidth < 1050 ?
                            <NavigationBarMobile />
                            :
                            <NavigationBarDesktop />
                        }
                        {this.renderForm()}
                    </div>
                )
            }
            case 1: {
                return (
                    <div>
                        <Helmet>
                            <title>{"Jake Whitesell – Contact"}</title>
                            <body className={"normal"} />
                        </Helmet>
                        {this.props.collapseButtonIsShowing ?
                            <NavigationBarMobile />
                            :
                            <NavigationBarDesktop />
                        }
                        {this.renderSuccess()}
                    </div>
                )
            }
            case 2: {
                return (
                    <div>
                        <Helmet>
                            <title>{"Jake Whitesell – Contact"}</title>
                            <body className={"normal"} />
                        </Helmet>
                        {this.props.collapseButtonIsShowing ?
                            <NavigationBarMobile />
                            :
                            <NavigationBarDesktop />
                        }
                        {this.renderFailure()}
                    </div>
                )
            }
        }


    }
}


const mapStateToProps = state => ({
    errors: state.errors,
    progress: state.progress
});

const mapSizesToProps = ({ width }) => ({
    collapseButtonIsShowing: width < 1200,
    width: width
})

export default connect(mapStateToProps, { submitContactForm })(withRouter(withSizes(mapSizesToProps)(ContactPage)));

// export default connect(
//     mapStateToProps,
//     { submitContactForm }
// )(withRouter(ContactPage));

// export default ContactPage;