import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { deleteEvent } from "../../actions/adminActions";
import { Button } from 'reactstrap';

class EventDataTable extends Component {

    constructor(props) {
        super(props);
        console.log('EventDataTable.constructor called.');
        console.log('value of this.props.deleteGroup: ' + this.props.deleteGroup);
        // console.log('value of this.props.bogus: ' + this.props.bogus);

        this.state = {
            mode: this.props.mode // 0 = 'view groups' mode
        };
    }


    convert24HourTo12(hours, minutes) {
        var timeString = ""
        var minuteString = ""

        if (minutes < 10) {
            minuteString = "0" + minutes
        } else {
            minuteString = minutes
        }

        if (hours > 12) {
            timeString = (hours - 12) + ":" + minuteString + " PM"
        } else {
            timeString = hours + ":" + minuteString + " AM"
        }

        return timeString
    }

    render() {

        const obj = this.props.obj;

        const startDateObject = new Date(this.props.obj.startDate)
        const startTimeString = this.convert24HourTo12(startDateObject.getHours(), startDateObject.getMinutes())
        const startDateString = startDateObject.toDateString() + " " + startTimeString

        return (
            <tr style={{ color: "white" }}>
                {/* <td>
                    {this.props.obj._id}
                </td> */}
                <td>
                    {this.props.obj.group}
                </td>
                <td>
                    {this.props.obj.groupLink}
                </td>
                <td>
                    {this.props.obj.venue}
                </td>
                <td>
                    {this.props.obj.venueLink}
                </td>
                <td>
                    {this.props.obj.location}
                </td>
                <td>
                    {this.props.obj.title}
                </td>
                <td>
                    {this.props.obj.description}
                </td>
                <td>
                    {startDateString}
                </td>
                <td>
                    {this.props.obj.timeText}
                </td>
                <td>
                    {this.props.obj.ticketsLink}
                </td>
                <td>
                    <Button color="danger" /*outline*/ onClick={e =>
                        window.confirm("Are you sure you want to delete this event?") &&
                        this.props.deleteEventFunction(obj._id)
                    }>
                        Delete
                    </Button>
                </td>
                <td>
                    <Button color="primary" /*outline*/ onClick={() => { this.props.editEventFunction(obj._id) }}>
                        Edit
                    </Button>
                </td>
                <td>
                    <Button color="primary" /*outline*/ onClick={() => { this.props.duplicateEventFunction(obj._id) }}>
                        Duplicate
                    </Button>
                </td>

            </tr>
        );

    }
}

EventDataTable.propTypes = {
    deleteEvent: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { deleteEvent }
)(EventDataTable);

// export default DataTable;
