import React, { Component } from "react";
import Footer from '../layout/Footer';

import NavigationBarMobile from '../layout/NavigationBarMobile';
import NavigationBarDesktop from '../layout/NavigationBarDesktop';

import Helmet from 'react-helmet';
import withSizes from 'react-sizes'

import PhotoGallery from './PhotoGallery';

import JakeSamVT from '../../images/JakeSamVT.jpg'
import Darkside1 from '../../images/Darkside1.jpg'
import Darkside2 from '../../images/Darkside2.jpg'
import JakeFG from '../../images/JakeFG.jpg'
import JakePromo from '../../images/JakePromo.jpg'
import VTbackdrop from '../../images/VTbackdrop.jpg'
import AFA from '../../images/AFA.jpg'
// import ISBhorns1 from '../../images/ISBhorns1.jpg'
// import ISBhorns2 from '../../images/ISBhorns2.jpg'
// import ISBhornsKat from '../../images/ISBhornsKat.jpg'
import ISBposter from '../../images/ISBposter.jpg'
import JakeVJC from '../../images/JakeVJC.jpg'
// import ISBhornsGrippo from '../../images/ISBhornsGrippo.jpg'
// import JakeCraig from '../../images/JakeCraig.jpg'
import JakeEugene from '../../images/JakeEugene.jpg'
import JoeyLeoneCS from '../../images/JoeyLeoneCS.jpg'
import JakeGuy from '../../images/JakeGuy.jpg'
import JakeJohnPizzarelli from '../../images/JakeJohnPizzarelli.jpg'
import JakePeterAndrew from '../../images/JakePeterAndrew.jpg'
import JakeScott2 from '../../images/JakeScott2.jpg'
import JakeGrace from '../../images/JakeGrace.jpg'
import jake3 from '../../images/jake-3.jpg'
import JakeAltoBDJF from '../../images/JakeAltoBDJF.jpg'
import ISBhorns3 from '../../images/ISBhorns3.jpg'
import Grift2019 from '../../images/Grift-2019.jpg'
import Guagua2019 from '../../images/Guagua-2019.jpg'
import ISBhorns4HG from "../../images/ISBhorns4-HG.jpg"
import ISBhorns5HG from "../../images/ISBhorns5-HG.jpg"
import JakeBari from "../../images/JakeBari.jpg"
import JakeKat1 from "../../images/JakeKat1-2018.jpg"
import JakeKat2 from "../../images/JakeKat2.jpg"
import JakeKat3Strangecreek2019 from "../../images/JakeKat3.jpg"
import JakeSamCasual from "../../images/JakeSam-Casual.jpg"
import JakeTenor1 from "../../images/JakeTenor1.jpg"
import JakeTenor2 from "../../images/JakeTenor2.jpg"
import JakeTenor3 from "../../images/JakeTenor3-Leunigs2017.jpg"
import NateReitCollage2018 from "../../images/NateReitCollage-2018.jpg"
import KatBandTelefunken from "../../images/KatBand-Telefunken2018.jpg"
import JakeRecorder from "../../images/Recorder-RioGrande-TaosNM.jpg"
import WBQFlynnspaceCrop from "../../images/WBQ-Flynnspace-Cropped.jpg"
import WBQlynnspaceNoSam from "../../images/WBQ-Flynnspace-noSam.jpg"
import WBQLeunigs2017 from "../../images/WBQLeunigs.jpg"
import YarnLastYaltz from "../../images/Yarn-LastYaltz-RoosterWalk-2018.jpg"
import JakeHeadshot from '../../images/jake-headshot-vignette.jpg'
import CFI from '../../images/CFI.jpg'
import JakeSoprano from "../../images/JakeSoprano.jpg"
import JakeRoof1 from "../../images/JakeRoof1.jpg"
import CannonballQuintet from "../../images/CannonballQuintet1-small.jpg"
import JakeBalcony from "../../images/JakeBalcony-small.jpg"

const photos = [
  {
    src: JakeHeadshot,
    width: 2832,
    height: 3544,
    title: "Jake Whitesell"
  },
  {
    src: JakeBalcony,
    width: 2688,
    height: 1792,
    title: "Jake Whitesell, alto sax, balcony (Photo by Luke Awtry)"
  },
  {
    src: jake3,
    width: 0.78025478,
    height: 1,
    title: "Jake, alto sax"
  },
  
  {
    src: JakeRoof1,
    width: 739,
    height: 1064,
    title: "Jake, alto sax"
  },
  
    {
    src: ISBhorns5HG,
    width: 1242,
    height: 1750,
    title: 'Kat Wright "Indomitable Horns" at Higher Ground'
  },
  {
    src: ISBhorns4HG,
    width: 1686,
    height: 2400,
    title: 'Kat Wright "Indomitable Horns" at Higher Ground'
  },
  {
    src: WBQLeunigs2017,
    width: 1270,
    height: 602,
    title: "Whitesell Brothers Quartet at Leunigs, Burlington Discover Jazz Festival (Photo by Luke Awtry)"
  },
//  {
//    src: ISBhorns3,
//    width: 1,
//    height: 0.55729167,
//    title: 'Kat Wright "Indomitable Horns"'
//  },
//   {
//    src: ISBhornsKat,
//    width: 1,
//    height: 0.73503185,
//    title: 'Kat Wright & "Indomitable Horns"'
//  },


  {
    src: JakeSoprano,
    width: 2048,
    height: 1365,
    title: 'Jake, soprano & alto saxes'
  },

  {
    src: JakeKat2,
    width: 700,
    height: 839,
    title: 'Jake & Kat Wright'
  },
  {
    src: JakeKat3Strangecreek2019,
    width: 2048,
    height: 1365,
    title: 'Jake & Kat Wright, Strangecreek Music Festival, 2019'
  },
//  {
//    src: JakeKat1,
//    width: 720,
//    height: 1280,
//    title: 'Jake & Kat Wright, 2018'
//  },
  
{
  src: JakeAltoBDJF,
  width: 846,
  height: 1095,
  title: "Jake, alto sax"
},

{
    src: YarnLastYaltz,
    width: 2048,
    height: 1359,
    title: 'Yarn (& Friends): "The Last Yaltz" at Rooster Walk Festival, 2018'
  },
  {
    src: JakeJohnPizzarelli,
    width: 4,
    height: 3,
    title: "Jake with John Pizzarelli"
  },
  {
    src: Darkside1,
    width: 3,
    height: 2,
    title: "Dark Side of the Mountain at The Rusty Nail, Stowe VT"
  },
//  {
//    src: Darkside2,
//    width: 0.67164179,
//    height: 1,
//    title: "Dark Side of the Mountain at The Rusty Nail, Stowe VT"
//  },
// {
//    src: AFA,
//    width: 1,
//    height: 0.64013672,
//    title: "A Fly Allusion"
//  },
  
  {
    src: CannonballQuintet,
    width: 3360,
    height: 2240,
    title: "Jake Whitesell's Cannonball Quintet"
  },
  {
    src: JakeSamCasual,
    width: 4032,
    height: 3024,
    title: "Jake & Sam Whitesell"
  },
  {
    src: JakeSamVT,
    width: 0.97506679,
    height: 1,
    title: "Jake & Sam Whitesell"
  },
  {
    src: Guagua2019,
    width: 1936,
    height: 1296,
    title: "Guagua, 2019"
  },
  {
    src: CFI,
    width: 1770,
    height: 1557,
    title: "Chicken Fat Injection, 2022"
  },
    {
    src: Grift2019,
    width: 1813,
    height: 1247,
    title: "The Grift, 2019"
  },
  {
    src: JoeyLeoneCS,
    width: 1,
    height: 0.74179431,
    title: "Joey Leone's Chop Shop"
  },
  {
    src: KatBandTelefunken,
    width: 1080,
    height: 720,
    title: "Kat Wright Band at Telefunken Studio, 2018"
  },
  //{
  //  src: JakeFG,
  //  width: 0.83557047,
  //  height: 1,
  //  title: "Jake, alto sax"
  //},
  
  //{
  //  src: VTbackdrop,
  //  width: 3,
  //  height: 4,
  //  title: "Jake in VT"
  // },
  
  // {
  //   src: ISBhorns1,
  //   width: 3,
  //   height: 2,
  //   title: "Indomitable Soul Band horns 1"
  // },
  // {
  //   src: ISBhorns2,
  //   width: 3,
  //   height: 2,
  //   title: "Indomitable Soul Band horns 2"
  // },
  // {
  //   src: ISBposter,
  //   width: 0.64697266,
  //   height: 1,
  //   title: "Kat Wright & The Indomitable Soul Band poster"
  // },
  //{
  //  src: JakeVJC,
  //  width: 1,
  //  height: 0.838888,
  //  title: "Jake at The Vermont Jazz Center"
  //},
  {
    src: NateReitCollage2018,
    width: 1824,
    height: 1203,
    title: "Nate Reit's Collage, 2018"
  },
  {
    src: JakePeterAndrew,
    width: 1,
    height: 0.48529412,
    title: "Jake Whitesell Trio (with Peter Krag & Andrew Magennis)"
  },
{
  src: JakeTenor2,
  width: 1242,
  height: 2148,
  title: "Jake, tenor sax"
},

  {
    src: JakeTenor3,
    width: 1023,
    height: 1356,
    title: "Jake, tenor sax (photo by Luke Awtry)"
  },
  { 
    src: JakeBari,
    width: 695,
    height: 916,
    title: "Jake, bari sax"
  },
  // {
  //   src: ISBhornsGrippo,
  //   width: 3,
  //   height: 2,
  //   title: "with Dave Grippo (alto) and Dave Purcell (trumpet)"
  // },
  // {
  //  src: JakeCraig,
  //  width: 1,
  //  height: 0.788888,
  //  title: "with Craig Eastman"
  //},
//  {
//    src: JakeEugene,
//    width: 0.844444,
//    height: 1,
//    title: "Jake & Eugene Uman, Vermont Jazz Center"
//  },
//  {
//    src: JakeScott2,
//    width: 0.92236328,
//    height: 1,
//    title: "Jake with Scott Mullett"
//  },
//  {
//    src: JakeGrace,
//   width: 0.6953748,
//    height: 1,
//    title: "Jake with Grace Kelly"
//  },
//  {
//    src: JakeGuy,
//   width: 1,
//    height: 0.72516556,
//    title: "Jake with Guy DeVito"
//  },
 
//  {
//    src: JakeTenor1,
//    width: 608,
//    height: 870,
//   title: "Jake, tenor sax"
//  },
  {
    src: JakeRecorder,
    width: 960,
    height: 720,
    title: "Rio Grande, Taos, NM (Kat Wright Tour)"
  },
  // {
  //  src: WBQFlynnspaceCrop,
  //  width: 5960,
  //  height: 3070,
  //  title: "Whitesell Brothers Quartet at FlynnSpace, 2018"
  //},
  //{
  //  src: WBQlynnspaceNoSam,
  //  width: 5148,
  //  height: 3352,
  //  title: "Whitesell Brothers Quartet at FlynnSpace (without Sam), 2018"
  //},
  
];

class PhotosPage extends Component {

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);

    this.state = {
      navbarIsOpen: false
    }

  }

  toggle() {
    this.setState({
      navbarIsOpen: !this.state.navbarIsOpen
    })
  }

  render() {

    return (

      <div>
        <Helmet>
          <title>{"Jake Whitesell – Photos"}</title>
          <body className={"normal"} />
        </Helmet>
        {/* <NavigationBar_New2 /> */}
        {window.innerWidth < 1050 ?
          <NavigationBarMobile />
          :
          <NavigationBarDesktop />
        }

        <div className="container">

          <div className="row">
            <div className="col-lg-12">
              <p className="customHeader" style={{ textAlign: 'center' }}>
                Photos
              </p>
            </div>
          </div>

          <div style={{ height: "25px" }}></div>

          <PhotoGallery photos={photos} />

        </div>

        <Footer />
      </div>
    );
  }
}

// export default PhotosPage;

const mapSizesToProps = ({ width }) => ({
  collapseButtonIsShowing: width < 1200,
  width: width
})

export default (withSizes(mapSizesToProps)(PhotosPage))
