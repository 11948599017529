import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import withSizes from 'react-sizes'
import axios from 'axios';
import UserDataTable from './UserDataTable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { decrementProgress, incrementProgress } from '../../actions/progress';
import { deleteUser } from "../../actions/adminActions";
import Footer from "../layout/Footer";

import NavigationBarMobile from '../layout/NavigationBarMobile'
import NavigationBarDesktop from '../layout/NavigationBarDesktop'

class ViewUsers extends Component {

    constructor(props) {
        super(props);

        console.log('ViewUsers.constructor called.');
        console.log('value of this.props.deleteUser: ' + this.props.deleteUser);
        console.log('value of this.props.bogus: ' + this.props.bogus);

        // bound functions
        this.deleteUserFunction = this.deleteUserFunction.bind(this);
        this.editUserFunction = this.editUserFunction.bind(this);

        this.state = { usersCollection: [] };

    }

    componentDidMount() {
        console.log('ViewUsers.componentDidMount called.')

        const url = global.serverAddress + '/api/users';

        axios.get(url)
            .then(res => {
                this.setState({ usersCollection: res.data });
            })
            .catch(function (error) {
                console.log(error);
            })
    }


    editUserFunction(id) {
        console.log('running ViewUsers.editUserFunction; value of id passed: ' + id);
        console.log('running ViewUsers.editUserFunction; value of this.props.history: ' + this.props.history);


        const url = global.serverAddress + '/api/users/edit/' + id;
        // const url = 'http://localhost:5000/api/users/edit/' + id


        axios.get(url)
            // axios.get('/api/users/edit')            
            .then(res => {
                console.log('reached second then block; value of res:');
                console.log(res);
                this.props.history.push({
                    pathname: '/admin-panel/edit-user',
                    search: '?query=abc',
                    state: { user: res.data }
                })
            })
            .catch(function (error) {
                console.log('reached catch block; error:');
                console.log(error);
            })
    }

    dataTable() {
        return this.state.usersCollection.map((data, i) => {
            console.log('creating data table for user number ' + i)
            return <UserDataTable mode={0} obj={data} key={i} deleteUserFunction={this.deleteUserFunction} editUserFunction={this.editUserFunction} />;
        });
    }

    deleteUserFunction(id) {

        console.log('ViewUsers.deleteUserFunction called.');
        console.log('value of this.props.deleteUser: ' + this.props.deleteUser);
        console.log('value of id: ' + id);
        this.props.deleteUser(id, this.updateState);

    }


    updateState = (newState) => {
        console.log('running ViewUsers.updateState.');

        // this.setState({ usersCollection: newState });
        this.componentDidMount();
    }

    render() {
        console.log('running ViewUsers.render; value of this.props.history: ' + this.props.history);
        return (
            <div className="wrapper-users">
                <Helmet bodyAttributes={{ style: "background-color: #000000" }}>
                    <title>{"Jake Whitesell – View Users"}</title>
                </Helmet>
                {this.props.collapseButtonIsShowing ?
                    <NavigationBarMobile />
                    :
                    <NavigationBarDesktop />
                }
                <div className="container-fluid">

                    <div style={{ height: "128px" }}></div>

                    <div className="row">
                        <div className="col-lg-12">
                            <p className="customHeader" style={{ textAlign: 'center' }}>
                                Users
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">

                            <div className="float-left">
                                <Link to="/admin-panel">
                                    Back
                                </Link>
                            </div>

                            <div className="float-right">
                                <Link to="/admin-panel/add-user">
                                    Add User
                                </Link>
                            </div>

                        </div>
                    </div>

                    <div style={{ height: "32px" }}></div>


                    <table className="table table-striped">
                        <thead className="thead-dark" style={{ color: "white" }}>
                            <tr>
                                <td>ID</td>
                                <td>First Name</td>
                                <td>Last Name</td>
                                <td>Email</td>
                                <td>Roles</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {this.dataTable()}
                        </tbody>
                    </table>
                </div>
                <Footer />
            </div>
        )
    }
}

ViewUsers.propTypes = {
    deleteUser: PropTypes.func.isRequired,
    incrementProgress: PropTypes.func.isRequired,
    decrementProgress: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth
});

const mapSizesToProps = ({ width }) => ({
    collapseButtonIsShowing: width < 1200,
    width: width
})

export default connect(mapStateToProps, { deleteUser, incrementProgress, decrementProgress, withRouter })(withSizes(mapSizesToProps)(ViewUsers));
