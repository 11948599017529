import { SET_CURRENT_USER, USER_LOADING, UPDATE_CURRENT_USER } from "../actions/types";

const isEmpty = require("is-empty");

const initialState = {
  isAuthenticated: false,
  user: {},
  loading: false
};

export default function (state = initialState, action) {
  console.log('running authReducer default function.');
  switch (action.type) {
    case SET_CURRENT_USER:
      console.log('case SET_CURRENT_USER.');
      console.log('value of JSON.stringify(action.payload): ' + JSON.stringify(action.payload));
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload
      };
    case USER_LOADING:
      console.log('case USER_LOADING.');
      return {
        ...state,
        loading: true
      };
    case UPDATE_CURRENT_USER:
      console.log('case UPDATE_CURRENT_USER.');
      console.log('value of JSON.stringify(action.payload): ' + JSON.stringify(action.payload));    

      const existingUser = state.user;

      const newUser = {
        id: existingUser.id,
        firstName: existingUser.firstName,
        lastName: existingUser.lastName,
        email: existingUser.email,
        courses: action.payload.courses,
        roles: existingUser.roles,
        iat: existingUser.iat,
        exp: existingUser.exp
      }

      const newState = Object.assign({}, state);
      newState.user = newUser;
      return newState;

      // return {
      //   ...state,
      //   // isAuthenticated: !isEmpty(action.payload),
      //   user: newUser
      // };

    default:
      console.log('default case..');
      return state;
  }
}
