import React from "react";
import { Route, Redirect } from "react-router-dom";
// import { connect } from "react-redux";
// import PropTypes from "prop-types";

export default function PrivateRoute({ component: Component, ...rest }) {
    return (
        <Route
            render={(props) => {
                return ((rest.isAuthenticated) && (rest.user.roles.includes('admin'))) ? (
                    <Component {...props} {...rest} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {
                                from: props.location
                            }
                        }}
                    />
                );
            }}
        />
    );
}

// PrivateRoute.propTypes = {
//   auth: PropTypes.object.isRequired
// };

// const mapStateToProps = state => ({
//   auth: state.auth
// });

// export default connect(mapStateToProps)(PrivateRoute);