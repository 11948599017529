import React, { useState, useCallback } from "react";
import { render } from "react-dom";
import Gallery, { Photo } from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";



// NEW

class PhotoGallery extends React.Component {
    state = { modalIsOpen: false, currentIndex: 0 };

    openModal = (event, { photo, index }) => {
        this.setState(state => ({ modalIsOpen: !state.modalIsOpen, currentIndex: index }));
    };

    closeModal = () => {
        this.setState(state => ({ modalIsOpen: !state.modalIsOpen, currentIndex: 0 }));
    };

    onImageChange = (index) => {
        console.log(index)
        this.setState(state => ({ currentIndex: index }));
    };

    render() {
        const { modalIsOpen } = this.state;
        const photos = this.props.photos

        const CustomModalFooter = ({ currentIndex, views }) => {
            const activeView = currentIndex + 1;
            const totalViews = views.length;

            const captionFontSize = window.innerWidth > 992 ? '20px' : '16px'
            const photoCreditFontSize = window.innerWidth > 992 ? '14px' : '12px'

            if (!activeView || !totalViews) return null;
            return (
                <>
                    <div style={{ textAlign: 'center', margin: 'auto' }}>
                        <p className="customText" style={{ fontSize: captionFontSize, textAlign: 'center' }}>
                            {photos[currentIndex].title}
                        </p>
                        {photos[currentIndex].credit == undefined ?
                            <p>&nbsp;</p>
                            :
                            <p className="customText" style={{ fontSize: photoCreditFontSize, textAlign: 'center' }}>
                                Photo credit: {photos[currentIndex].credit}
                            </p>
                        }

                    </div>
                    <span class="react-images__footer__count css-w6xjhe css-1ycyyax">
                        {activeView} / {totalViews}
                    </span>
                </>
            );
        };

        return (
            <>
                {/* <button
                    type="button"
                    className="btn-fullScreen"
                    onClick={this.toggleModal}
                >
                    Open Modal
                </button> */}
                <Gallery photos={photos} onClick={this.openModal} />

                <ModalGateway>
                    {modalIsOpen ? (
                        <Modal onClose={this.closeModal}>
                            <Carousel
                                currentIndex={this.state.currentIndex}
                                components={{ FooterCount: CustomModalFooter }}
                                views={photos}
                            // views={photos.map(x => ({
                            //     ...x,
                            //     srcset: x.srcSet,
                            //     caption: x.title,
                            //     title: x.title
                            // }))}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway>

            </>
        );
    }
}

export default PhotoGallery;
