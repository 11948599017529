import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
// import { connect } from 'react-redux';
import { decrementProgress, incrementProgress } from './progress';

import { GET_ERRORS, SET_CURRENT_USER, /*USER_LOADING,*/ UPDATE_CURRENT_USER } from "./types";

// Reset Password
export const resetPassword = (emailAddress, completion) => dispatch => {

  console.log('running authActions.resetPassword()');
  console.log('value of completion: ' + completion);
  console.log('value of emailAddress: ' + emailAddress);

  // turn on spinner
  dispatch(incrementProgress());

  axios
    .post("/api/users/passwordReset", emailAddress)
    .then(res => {
      console.log('running then block.');
      dispatch(decrementProgress());
      completion(res.data);
    })
    .catch(err => {
      console.log('running catch block.');
      dispatch(decrementProgress());
      // dispatch({
      //   type: GET_ERRORS,
      //   payload: err.data
      // })
      completion(false);
    })

};


// Register User
export const registerUser = (userData, history) => dispatch => {

  // turn on spinner
  dispatch(incrementProgress());

  axios
    .post("/api/users/register", userData)
    .then(res => history.push("/login"))
    .then(() => {
      dispatch(decrementProgress());
    })
    .catch(err => {
      dispatch(decrementProgress());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    }
    );
};

// Login - get user token
export const loginUser = userData => dispatch => {

  console.log('running authActions.loginUser.');

  // turn on spinner
  dispatch(incrementProgress());

  axios
    .post("/api/users/login", userData)
    .then(res => {
      // Save to localStorage

      // Set token to localStorage
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));

      // console.log('contents of decoded user name: ' + decoded.name);
      // console.log('contents of decoded user email: ' + decoded.email);
      // console.log('contents of decoded user id: ' + decoded.id);
      // console.log('contents of decoded user roles: ' + decoded.roles);

    })
    .then(() => {
      dispatch(decrementProgress());
    })
    .catch(err => {
      dispatch(decrementProgress());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    }

    );
  // .catch(err =>
  //   dispatch(decrementProgress(), {
  //     type: GET_ERRORS,
  //     payload: err.response.data
  //   })
  // );
};

// Set logged in user
export const setCurrentUser = decoded => {
  console.log('running authActions.setCurrentUser.');
  console.log('value of JSON.stringify(decoded): ' + JSON.stringify(decoded));
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

// User loading
// export const setUserLoading = () => {
//   console.log('running authActions.setUserLoading.');
//   return {
//     type: USER_LOADING
//   };
// };

// Log user out
export const logoutUser = () => dispatch => {
  console.log('running authActions.logoutUser.');
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};

export const updateCurrentUser = updatedUser => {
  console.log('running updateCurrentUser.');
  return {
    type: UPDATE_CURRENT_USER,
    payload: updatedUser
  };
}
