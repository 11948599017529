import React, { Component } from "react";
import Footer from '../../layout/Footer';

import NavigationBarMobile from '../../layout/NavigationBarMobile';
import NavigationBarDesktop from '../../layout/NavigationBarDesktop';

import Helmet from 'react-helmet';
import withSizes from 'react-sizes'

import JakeSam from "../../../images/JakeSam-Casual.jpg";
import WBQ from "../../../images/WBQLeunigs.jpg";

class WBQPage extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = {
            navbarIsOpen: false
        }
    }

    toggle() {
        this.setState({
            navbarIsOpen: !this.state.navbarIsOpen
        })
    }

    render() {

        const paragraphMargin = this.props.collapseButtonIsShowing ? "0px" : "20px"

        return (
            <div>
                <Helmet>
                    <title>{"Whitesell Brothers Quartet"}</title>
                    <body className={"normal"} />
                </Helmet>
                {window.innerWidth < 1050 ?
                    <NavigationBarMobile />
                    :
                    <NavigationBarDesktop />
                }

                <div className="row">
                    <div className="col-lg-12">
                        <p className="customHeader" style={{ textAlign: 'center'}}>
                            The Whitesell Brothers Quartet
                        </p>
                    </div>
                </div>

                <div style={{ height: "25px" }}></div>

                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8" style={this.props.width < 1150 ? { textAlign: "center" } : {}}>

                        {this.props.width < 1150 ?
                            <img className="img-fluid" style={{ width: "100%", marginBottom: "16px" }} src={JakeSam} />
                            :
                            <img className="img-fluid float-left" style={{ width: "40vw", marginRight: "24px", marginBottom: "16px" }} src={JakeSam} />
                        }

                    {this.props.width < 1150 ? <div style={{ height: "16px" }}></div>  : <b></b> }

                        <p className="customText" style={{ textAlign: "justify" }}>
                            The Whitesell Brothers Quartet presents original music inspired by beauty, joy, introspection, and interconnection.
                        </p> 

                        {this.props.width < 1150 ? <div style={{ height: "8px" }}></div>  : <b></b> }

                        <p className="customText" style={{ textAlign: "justify" }}>
                            Twin brothers <a href="./about">Jake Whitesell</a> (saxophone) and <a href="http://www.samuelwhitesell.com">Sam Whitesell</a> (piano) have been performing together for over two decades. They grew up in Potsdam, NY, studying and performing music in several idioms, including classical, Celtic, jazz, and rock. In high school, they dug deeply into classic jazz recordings and formed a working jazz combo. They furthered their studies in jazz and classical performance at the Crane School of Music at SUNY Potsdam, graduating in 2005.
                        </p>

                        <p className="customText" style={{ textAlign: "justify" }}>
                            <a href="http://www.jakewhitesell.com">Jake Whitesell</a> moved to central Vermont in 2010 and is now one Vermont's most in-demand freelance saxophonists and jazz musicians. Jake performs regularly with his own jazz groups and a variety of other artists and bands including <a href="http://www.guaguavt.com" target="_blank">Gua Gua</a>, <a href="https://www.kerubomusic.com" target="_blank">Kerubo</a>, and George Petit's <a href="https://www.facebook.com/profile.php?id=100064116901311">Chicken Fat Injection</a>. As lead alto saxophonist with Wally Siebel's All-Star Big Band in Potsdam, NY, Jake has backed up jazz greats <a href="https://www.johnpizzarelli.com" target="_blank">John Pizzarelli</a>, <a href="http://garysmulyan.com/" target="_blank">Gary Smulyan</a>, <a href="http://kenpeplowski.com/" target="_blank">Ken Peplowski</a>, <a href="http://www.stevewilsonmusic.com/" target="_blank">Steve Wilson</a>, <a href="https://www.tommyigoe.com" target="_blank">Tommy Igoe</a>, and <a href="https://dennismackrelmusic.com" target="_blank">Dennis Mackrel</a>. Jake toured extensively with <a href="https://www.katwright.com" target="_blank">Kat Wright</a> from 2009-2021. Jake has also performed with <a href="http://www.temptationsofficial.com/" target="_blank">The Temptations</a>, <a href="http://www.rayvegamusic.com/" target="_blank">Ray Vega</a>, and The Dartmouth Gospel Choir, among others.
                        </p>

                        <p className="customText" style={{ textAlign: "justify" }}>
                            <a href="http://www.samuelwhitesell.com/">Sam Whitesell</a> moved to Burlington in 2016 after completing a Masters degree in piano performance and pedagogy at the Crane School of Music. Sam brings a unique mix of sensitivity, spirit, and passion to each of his performances. He loves creating music that touches the spirit and makes it dance, whisking away the listener to unexpected realms. Sam runs a dynamic music teaching studio in Burlington and enjoys performing in a wide array of musical contexts in the area.
                        </p>

                        <p className="customText" style={{ textAlign: "justify" }}>
                            The Whitesell brothers are supported by top Vermont jazz musicians John Rivers (bass) and Geza Carr (drums).
                        </p>

                        <p className="customText" style={{ textAlign: "justify" }}>
                            The Whitesell Brothers Quartet has appeared at the Burlington Discover Jazz Festival annually since 2013, at the Stowe Jazz Festival in 2019, at the Newport Vermont Jazz Festival in 2015, and at the Flynn Space for First Night Burlington in 2017.
                        </p>

                        <br/>
                            <img className="img-fluid" style={{ width: "100%", marginBottom: "16px" }} src={WBQ} />
                        
                            <div style={{ height: "40px" }}></div>

                        <p className="customText" style={{ color:"#66AAFF", textAlign: "center" }}> Listen to the Whitesell Brothers Quartet:</p>
                    </div>
                    <div className="col-md-2"></div>
                </div>

                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">

                        {/* <p className="customText" style={{ marginBottom: "0px" }}>Jazz Duo (cocktail hour / background music)</p> */}

                        <iframe width="100%" height="310px" scrolling="no" frameborder="no" src="https://w.soundcloud.com/player/?url=http%3A%2F%2Fapi.soundcloud.com%2Fplaylists%2F3916565">
                            https://soundcloud.com/jakewhitesell/sets/quartet-demo-2013
                        </iframe>

                    </div>
                    <div className="col-md-2"></div>
                </div>




                <Footer />
            </div>
        );
    }
}

const mapSizesToProps = ({ width }) => ({
    collapseButtonIsShowing: width < 1200,
    width: width
})

export default (withSizes(mapSizesToProps)(WBQPage))
