
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button } from 'reactstrap';
import Helmet from 'react-helmet';
import withSizes from 'react-sizes'
// import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerUser } from "../../actions/authActions";
import classnames from "classnames";
import axios from "axios";
import Footer from "../layout/Footer";

import NavigationBarMobile from '../layout/NavigationBarMobile'
import NavigationBarDesktop from '../layout/NavigationBarDesktop'

const inputParsers = {
    date(input) {
        const [month, day, year] = input.split('/');
        return `${year}-${month}-${day}`;
    },
    uppercase(input) {
        return input.toUpperCase();
    },
    number(input) {
        return parseFloat(input);
    },
};

class ResetPassword extends Component {
    constructor() {
        super();

        this.state = {
            isAuthenticated: false,
            passwordResetAttempted: false,
            passwordResetSucceeded: false,
            password: "",
            // password2: "",
            errors: {},

            id: "",
            token: ""
        };
    }

    componentDidMount() {
        console.log("componentDidMount called.")
        const id = this.props.match.params.id;
        const token = this.props.match.params.token;

        console.log('value of this.props.match.params.id: ' + this.props.match.params.id);
        console.log('value of this.props.match.params.token: ' + this.props.match.params.token);

        const url = global.serverAddress + '/api/users/_resetpassword/' + id + '/' + token;

        const body = {
            id: id,
            token: token
        };

        console.log('running axios.get(url, body)');
        axios.get(url, body)
            .then(res => {
                console.log('running .then() block.');
                console.log('value of res:');
                console.log(res);
                this.setState({
                    isAuthenticated: true,
                    id: res.data.id,
                    token: res.data.token
                });
                console.log('value of this.state.id: ' + this.state.id);
                console.log('value of this.state.token: ' + this.state.token);
            })


    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSubmit = e => {
        console.log('running onSubmit.')
        console.log('value of this.state.id: ' + this.state.id);
        console.log('value of this.state.token: ' + this.state.token);

        e.preventDefault();

        const errors = this.state.errors;

        const formUrl = global.serverAddress + '/api/users/resetpassword';

        console.log("errors: " + errors);

        const body = {
            id: this.state.id,
            token: this.state.token,
            password: this.state.password,
            // password2: this.state.password2
        };

        axios.post(formUrl, body).then(res => {
            this.setState({
                passwordResetAttempted: true,
                passwordResetSucceeded: true,
                password: ""
            })
        }).catch(err => {
            console.log('** error: ' + err);
            this.setState({
                passwordResetAttempted: true,
                passwordResetSucceeded: false,
                password: ""
            })
        })
        // this.props.registerUser(newUser, this.props.history);
    };


    renderForm() {

        console.log("running renderForm.");

        return (
            <div>
                <Helmet bodyAttributes={{ style: "background-color: #000000" }}>
                    <title>{"Jake Whitesell – Reset Password"}</title>
                </Helmet>
                {this.props.collapseButtonIsShowing ?
                    <NavigationBarMobile />
                    :
                    <NavigationBarDesktop />
                }

                <div style={{ height: "128px" }}></div>

                <div className="row">
                    <div className="col-lg-12">
                        <p className="customHeader" style={{ textAlign: 'center' }}>
                            Reset Password
                        </p>
                    </div>
                </div>

                <form noValidate onSubmit={this.onSubmit}>

                    <div className="form-group" style={{ paddingBottom: '15px' }}>
                        <label style={{ color: "white" }} htmlFor="password">Password</label>
                        <input
                            onChange={this.onChange}
                            value={this.state.password}
                            // error={errors.password}
                            id="password"
                            type="password"
                            // className={classnames("form-control", {
                            //     invalid: errors.password
                            // })}
                            className="form-control"
                        />
                        {/* <p className="text-danger float-right">{errors.password}</p>
                        <span className="red-text"><br />{errors.password}</span> */}
                    </div>

                    {/* <div className="form-group" style={{ paddingBottom: '15px' }}>
                        <label htmlFor="password2">Confirm Password</label>
                        <input
                            onChange={this.onChange}
                            value={this.state.password2}
                            error={errors.password2}
                            id="password2"
                            type="password"
                            className={classnames("form-control", {
                                invalid: errors.password2
                            })}
                        />
                        <p className="text-danger float-right">{errors.password2}</p>
                        <span className="red-text"><br />{errors.password2}</span>
                    </div> */}

                    <Button type="submit" color="primary" /*outline*/ className="float-right">
                        Reset
                    </Button>

                </form>


                {/* <form action={formUrl} method="POST">                
                    <input type="hidden" name="id" defaultValue={id} />
                    <input type="hidden" name="token" defaultValue={token} />
                    <input type="password" name="password" defaultValue="" placeholder="Enter new password..." />
                    <input type="submit" value="Reset Password" />
                </form> */}

            </div>
        )
    }

    renderLoadingIndicator() {
        return (
            <div>
                <h4>
                    Loading...
                </h4>
            </div>
        )
    }

    renderSuccess() {
        return (
            <div>
                <h4>
                    Password reset.
                </h4>
                <Link to="/login">Log in</Link>
            </div>
        )
    }

    renderFailure() {
        return (
            <div>
                <h4>
                    Password reset failed. Please try again.
                </h4>
            </div>
        )
    }

    render() {

        // const postUrl = global.serverAddress + '/api/users/resetpassword';
        // const { errors } = this.state;
        const { isAuthenticated, passwordResetAttempted, passwordResetSucceeded } = this.state;

        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4">

                            {(isAuthenticated) ?
                                (passwordResetAttempted) ?
                                    (passwordResetSucceeded) ?
                                        this.renderSuccess() : this.renderFailure()
                                    :
                                    this.renderForm() : this.renderLoadingIndicator()
                            }

                        </div>
                        <div className="col-lg-4"></div>
                    </div >
                    <Footer />
                </div>
            </div >
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

const mapSizesToProps = ({ width }) => ({
    collapseButtonIsShowing: width < 1200,
    width: width
})

export default connect(mapStateToProps, { registerUser })(withRouter(withSizes(mapSizesToProps)(ResetPassword)));