import React, { Component } from "react";
import Footer from '../layout/Footer';

import NavigationBarMobile from '../layout/NavigationBarMobile'
import NavigationBarDesktop from '../layout/NavigationBarDesktop'

import Helmet from 'react-helmet';
import withSizes from 'react-sizes'
import MediaBox from './MediaBox';

import CommonGround from "../../audio/CommonGround.mp3"
import EnoughIsPlenty from "../../audio/EnoughIsPlenty.mp3"

// audio



class ListenPage extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = {
            navbarIsOpen: false,
            numPages: 0,
        }

        var pageNumber = 1;
        var prevPageNumber = 1;
    }

    toggle() {
        this.setState({
            navbarIsOpen: !this.state.navbarIsOpen
        })
    }

    render() {

        return (

            <div>
                <Helmet>
                    <title>{"Jake Whitesell – Listen"}</title>
                    <body className={"normal"} />
                </Helmet>
                {window.innerWidth < 1050 ?
                    <NavigationBarMobile />
                    :
                    <NavigationBarDesktop />
                }
                

                <div className="row">
                    <div className="col-lg-12">
                        <p className="customHeader" style={{ textAlign: 'center' }}>
                            Listen
                        </p>
                    </div>
                </div>

                <div style={{ height: "25px" }}></div>
                
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8" style={{textAlign: "center"}}>

                        <p className="customTextSmall1" style={{marginBottom: "0px"}}>
                                "Common Ground" 
                            {(window.innerWidth < 400) ? <br/> : "" }    
                            <font style={{fontSize: "13px"}}> (by Mike Stern / arr. Tedd Firth)</font><br/>
                                Wally Siebel All-Star Big Band<br/>
                                <p className="customTextSmall1" style={{fontSize: "14px", marginBottom: "10px"}}>
                                (feat. Jake Whitesell, alto sax, soloist)<br/></p>
                        </p>
                        <audio class="customPlayer" title="Common Ground (alto sax, soloist)" controls style={{ width: '100%' }}>
                        <source src={CommonGround} />
                        Your browser does not support the <code>audio</code> element.
                        </audio>
                        <div style={{ height: "48px" }}></div>

                        <p className="customTextSmall1" style={{marginBottom: "0px"}}>
                                "Enough Is Plenty" 
                            <font style={{fontSize: "13px"}}> (by Robinson Morse)</font><br/>
                                Robinson Morse's Sound of Mind<br/></p>
                        <p className="customTextSmall1" style={{fontSize: "14px", marginBottom: "10px"}}>
                                (Jake Whitesell, tenor sax, soloist)<br/>
                        </p>
                        <audio class="customPlayer" title="Enough Is Plenty (tenor sax, soloist)" controls style={{ width: '100%'}}>
                        <source src={EnoughIsPlenty} />
                        Your browser does not support the <code>audio</code> element.
                        </audio>
                        <div style={{ height: "48px" }}></div>

                        <p className="customText" style={{marginBottom: "0px"}}>
                            <font color="#66AAFF">
                            Whitesell Brothers Duo "EP"</font> <br/>
                        </p>
                        <p className="customTextSmall1" style={{marginBottom: "5px"}}>
                            (jazz cocktail hour / dinner music)<br/></p>
                        <p className="customTextSmall1" style={{marginBottom: "0px", fontSize: "14px"}}>
                            Jake Whitesell (tenor sax, flute),<br/>
                            Sam Whitesell (piano)
                        </p>
                        <div style={{ height: "10px" }}></div>
                        {/* <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/40805368&color=ff5500"></iframe> */}
                        <iframe width="100%" height="375" scrolling="no" frameborder="no" src="https://w.soundcloud.com/player/?url=http%3A%2F%2Fapi.soundcloud.com%2Fplaylists%2F1780902">https://w.soundcloud.com/player/?url=http%3A%2F%2Fapi.soundcloud.com%2Fplaylists%2F1780902</iframe>

                        <div style={{ height: "48px" }}></div>

                        <p className="customText" style={{marginBottom: "0px"}}>
                        <font color="#66AAFF">
                            Whitesell Brothers Quartet "EP"</font><br/>
                        <font style={{fontSize: "15px"}}> (concert jazz)</font></p>
                        {/* <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/311577422&color=ff5500"></iframe> */}
                        <div style={{ height: "10px" }}></div>
                        <iframe width="100%" height="310px" scrolling="no" frameborder="no" src="https://w.soundcloud.com/player/?url=http%3A%2F%2Fapi.soundcloud.com%2Fplaylists%2F3916565">
                            https://soundcloud.com/jakewhitesell/sets/quartet-demo-2013
                        </iframe>

                    </div>
                    <div className="col-md-2"></div>
                </div>

                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8"></div>
                    <div className="col-md-2"></div>
                </div>


                <Footer />

            </div>
        );
    }
}

const mapSizesToProps = ({ width }) => ({
    collapseButtonIsShowing: width < 1200,
    width: width
})

export default (withSizes(mapSizesToProps)(ListenPage))
