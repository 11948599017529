import React, { Component } from "react";
import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
import { Button, withRouter } from 'reactstrap';
import Helmet from 'react-helmet';
import withSizes from 'react-sizes'
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import classnames from "classnames";
import Footer from "../layout/Footer";

import NavigationBarMobile from '../layout/NavigationBarMobile'
import NavigationBarDesktop from '../layout/NavigationBarDesktop'

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {}
    };
    console.log(this.props);
  }

  componentDidMount() {
    // If logged in and user navigates to Login page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/");
    }

    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const userData = {
      email: this.state.email,
      password: this.state.password
    };

    this.props.loginUser(userData);
  };

  render() {
    const { errors } = this.state;

    return (
      <div>
        <Helmet bodyAttributes={{ style: "background-color: #000000" }}>
          <title>{"Jake Whitesell – Login"}</title>
        </Helmet>
        {this.props.collapseButtonIsShowing ?
          <NavigationBarMobile />
          :
          <NavigationBarDesktop />
        }

        <div className="container">

          <div style={{height: "128px"}}></div>

          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <Link to="/">
                Back to Home
            </Link>

              <div style={{ paddingTop: '30px' }}></div>

              <div className="row">
                <div className="col-lg-12">
                  <p className="customHeader" style={{ textAlign: 'center' }}>
                    Log In
                  </p>
                </div>
              </div>

              <form noValidate id="loginForm" onSubmit={this.onSubmit}>

                <div className="form-group" style={{ paddingBottom: '15px' }}>

                  <input
                    onChange={this.onChange}
                    value={this.state.email}
                    error={errors.email}
                    id="email"
                    type="email"
                    className="form-control"
                  // className={classnames("form-control", {
                  //   invalid: errors.email || errors.emailnotfound
                  // })}                  
                  />

                  <label style={{ color: "white" }} htmlFor="email">Email</label>

                  {/* <span>
                  <p style={{ color: 'red' }}>
                    {errors.email}
                    {errors.emailnotfound}
                  </p>
                </span> */}

                </div>

                <div className="form-group" /*style={{ paddingBottom: '15px' }}*/>

                  <input
                    onChange={this.onChange}
                    value={this.state.password}
                    error={errors.password}
                    id="password"
                    type="password"
                    className="form-control"
                  // className={classnames("form-control", {
                  //   invalid: errors.password || errors.passwordincorrect
                  // })}
                  />

                  <label style={{ color: "white" }} htmlFor="password">Password</label>
                  <span>
                    <p style={{ color: 'red' }}>
                      {errors.password}
                      {/* {errors.passwordincorrect} */}
                      {errors.emailOrPasswordIncorrect}
                      {errors.email}
                      {/* {errors.emailnotfound} */}
                    </p>
                  </span>

                </div>

              </form>

            </div>

            <div className="col-md-4"></div>
          </div>

          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4" style={{ paddingBottom: '15px' }}>

              <p className="float-right" style={{ color: "white" }}>
                Don't have an account? <Link to="/register">Register</Link>
              </p>

              <p>&nbsp;</p>

              <p className="float-right" style={{ color: "white" }}>
                Forgot your password? <Link to="/forgotpassword">Reset</Link>
              </p>


            </div>

            <div className="col-md-4"></div>
          </div>

          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4">

              <Button type="submit" form="loginForm" color="primary" /*outline*/ className="float-right">
                Log In
            </Button>

            </div>

            <div className="col-md-4"></div>
          </div>

          <Footer />

        </div>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

const mapSizesToProps = ({ width }) => ({
  collapseButtonIsShowing: width < 1200,
  width: width
})

export default connect(mapStateToProps, { loginUser })(withSizes(mapSizesToProps)(Login));
