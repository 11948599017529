import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { deleteUser } from "../../actions/adminActions";
import { Button } from 'reactstrap';

class UserDataTable extends Component {

    constructor(props) {
        super(props);

        this.completionBlock = this.completionBlock.bind(this);

        this.state = {
            mode: this.props.mode // 0 = 'view users' mode, 1 = 'edit course' mode, 2 = 'add user to course' mode
        };

        console.log('UserDataTable.constructor called.');
        console.log('value of this.props.deleteUser: ' + this.props.deleteUser);
        console.log('value of this.props.mode: ' + this.props.mode);
        // console.log('value of this.props.bogus: ' + this.props.bogus);
    }


    completionBlock() {
        console.log('running adminActions.unregisterUserForCourse completion block.');
    }


    render() {

        const obj = this.props.obj;

        return (
            <tr style={{color: "white"}}>
                <td>
                    {this.props.obj._id}
                </td>
                <td>
                    {this.props.obj.firstName}
                </td>
                <td>
                    {this.props.obj.lastName}
                </td>
                <td>
                    {this.props.obj.email}
                </td>

                <td>
                    {this.props.obj.roles.join(", ")}
                </td>

                <td>
                    <Button color="danger" /*outline*/ onClick={e =>
                        window.confirm("Are you sure you want to delete this user?") &&
                        this.props.deleteUserFunction(obj._id)
                    }>
                        Delete
                    </Button>
                </td>

                <td>
                    <Button color="primary" /*outline*/ onClick={() => { this.props.editUserFunction(obj._id) }}>
                        Edit
                    </Button>
                </td>

            </tr>
        );  

    }
}

UserDataTable.propTypes = {
    deleteUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { deleteUser }
)(UserDataTable);

// export default DataTable;
