import React, { Component } from "react";
import { /*Link,*/ withRouter } from "react-router-dom";
import { Button } from 'reactstrap';
import Helmet from 'react-helmet';
import axios from 'axios';
import MultiImageInput from 'react-multiple-image-input';
import withSizes from 'react-sizes'


// import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addGroup } from "../../actions/adminActions";
import classnames from "classnames";
import Footer from "../layout/Footer";
import NavigationBarMobile from '../layout/NavigationBarMobile'
import NavigationBarDesktop from '../layout/NavigationBarDesktop'

class AddGroup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            description: "",
            images: {},
            errors: {},

            nameComparator: "",
            descriptionComparator: "",
            imagesComparator: {},
        };
        console.log(this.props);

        this.groupComparator = {
            name: this.state.name,
            description: this.state.description,
            images: this.state.images,
        }
    }


    componentWillReceiveProps(nextProps) {
        // if (nextProps.auth.isAuthenticated) {
        //     this.props.history.push("/");
        // }

        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }

    // Need to add methods from AddUser.js here

    fieldsHaveChanged() {
        if ((this.state.name !== this.groupComparator.name) || (this.state.description !== this.groupComparator.description) || (this.state.images !== this.groupComparator.images)) {
            return true;
        } else {
            return false;
        }
    }

    confirmDiscard() {
        console.log('running AddGroup.confirmDiscard().');
        window.confirm("Are you sure you want to go back? Any unsaved changes will be lost.") && this.props.history.goBack()
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSubmit = e => {
        e.preventDefault();

        console.log("running AddGroup.onSubmit.")
        console.log("value of this.state.images:")
        console.log(this.state.images)

        const groupData = {
            name: this.state.name,
            description: this.state.description,
            images: this.state.images,
        };

        this.props.addGroup(groupData, this.props.history);
    };

    render() {
        const { errors } = this.state;

        return (
            <div>
                <Helmet bodyAttributes={{ style: "background-color: #000000" }}>
                    <title>{"Jake Whitesell – Add Group"}</title>
                </Helmet>
                {this.props.collapseButtonIsShowing ?
                    <NavigationBarMobile />
                    :
                    <NavigationBarDesktop />
                }

                <div className="container">

                    <div style={{ height: "128px" }}></div>

                    <div className="row">
                        <div className="col-lg-12">
                            <Button color="primary" /*outline*/ onClick={e => this.fieldsHaveChanged() ? this.confirmDiscard() : this.props.history.goBack()}>
                                Back
                            </Button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">

                            {/* <Button color="primary" outline onClick={e => this.fieldsHaveChanged() ? this.confirmDiscard() : this.props.history.goBack()}>
                            Back
                        </Button>                         */}

                            <div className="row">
                                <div className="col-lg-12">
                                    <p className="customHeader" style={{ textAlign: 'center' }}>
                                        New Group
                                    </p>
                                </div>
                            </div>

                            <form noValidate onSubmit={this.onSubmit} id="addGroupForm">

                                <div className="form-group" style={{ paddingBottom: '15px' }}>
                                    <label style={{ color: "white" }} htmlFor="name">Name</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.name}
                                        error={errors.name}
                                        id="name"
                                        type="name"
                                        className={classnames("form-control", {
                                            invalid: errors.name
                                        })}
                                    />
                                    <p className="text-danger float-right">{errors.name}</p>
                                </div>

                                <div className="form-group">
                                    <label style={{ color: "white" }} htmlFor="description">Description</label>
                                    <textarea
                                        onChange={this.onChange}
                                        value={this.state.description}
                                        error={errors.description}
                                        id="description"
                                        type="description"
                                        className={classnames("form-control", {
                                            invalid: errors.description
                                        })}
                                        style={{ height: '30vh' }}
                                    />
                                    <p className="text-danger float-right">{errors.description}</p>
                                </div>

                                <div className="form-group">
                                    <div>
                                        <MultiImageInput
                                            images={this.state.images}
                                            setImages={value => this.setState({
                                                images: value
                                            })}
                                            // cropConfig={{ crop, ruleOfThirds: true }}
                                            allowCrop={false}
                                            max={9999}
                                        />
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12" style={{ paddingTop: '15px' }}>
                            <Button type="submit" form="addGroupForm" color="primary" /*outline*/ className="float-right">
                                Add
                            </Button>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

const mapSizesToProps = ({ width }) => ({
    collapseButtonIsShowing: width < 1200,
    width: width
})


export default connect(mapStateToProps, { addGroup })(withRouter(withSizes(mapSizesToProps)(AddGroup)));
