import axios from "axios";
// import setAuthToken from "../utils/setAuthToken";
// import jwt_decode from "jwt-decode";
// import { connect } from 'react-redux';
import { decrementProgress, incrementProgress } from './progress';
import { updateCurrentUser } from './authActions';

import { GET_ERRORS/*, SET_CURRENT_USER, USER_LOADING*/ } from "./types";

// Add Group
export const addGroup = (groupData, history) => dispatch => {    

    const url = '/api/groups/create';

    // turn on spinner
    dispatch(incrementProgress());    

    // NEW    

    axios
        .post(url, groupData)
        
        .then(() => {
            dispatch(decrementProgress());
        })
        .then(() => {
            history.push('/admin-panel/view-groups');
        })
        .catch(err => {
            dispatch(decrementProgress());
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        }
        );
};

// Update Group
export const updateGroup = (groupComparatorData, groupData, history) => dispatch => {
    console.log('adminActions.updateGroup called; id: ' + groupData.id);

    const url = '/api/groups/update/' + groupData.id;

    // turn on spinner
    dispatch(incrementProgress());    

    // NEW    

    axios
        .put(url, ({groupComparatorData: groupComparatorData, groupData: groupData}))
        
        .then(() => {
            dispatch(decrementProgress());
        })
        .then(() => {
            history.push('/admin-panel/view-groups');
        })
        .catch(err => {
            dispatch(decrementProgress());
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        }
        );

}

// Delete Group
export const deleteGroup = (id, completion) => dispatch => {
    console.log('adminActions.deleteGroup called; id: ' + id);

    // turn on spinner
    dispatch(incrementProgress());

    const url = '/api/groups/delete/' + id;
    axios
        .delete(url)
        .then(res => {
            completion(res.data);
        })
        .then(() => {
            dispatch(decrementProgress());
        })

}

// Add Event
export const addEvent = (eventData, history, completion) => dispatch => {
    // export const addEvent = (eventData, completion) => dispatch => {

        console.log("adminActions.addEvent called.")
        // console.log("DEBUG: value of completion:")
        // console.log(completion)

    const url = '/api/events/create';

    // turn on spinner
    dispatch(incrementProgress());    

    // NEW    

    axios
        .post(url, eventData)
        
        .then(() => {
            dispatch(decrementProgress());
        })
        .then(() => {
            history.push('/admin-panel/view-events');
            if (completion !== undefined) {
                completion()
            }
            // completion()
        })
        // .catch(err => {
        //     dispatch(decrementProgress());
        //     dispatch({
        //         type: GET_ERRORS,
        //         payload: err.response.data
        //     })
        // }
        // );
};

// Update Event
export const updateEvent = (eventComparatorData, eventData, history) => dispatch => {
    console.log('adminActions.updateEvent called; id: ' + eventData.id);

    const url = '/api/events/update/' + eventData.id;

    // turn on spinner
    dispatch(incrementProgress());    

    // NEW    

    axios
        .put(url, ({eventComparatorData: eventComparatorData, eventData: eventData}))
        
        .then(() => {
            dispatch(decrementProgress());
        })
        .then(() => {
            history.push('/admin-panel/view-events');
        })
        .catch(err => {
            dispatch(decrementProgress());
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        }
        );

}

// Delete Event
export const deleteEvent = (id, completion) => dispatch => {
    console.log('adminActions.deleteEvent called; id: ' + id);

    // turn on spinner
    dispatch(incrementProgress());

    const url = '/api/events/delete/' + id;
    axios
        .delete(url)
        .then(res => {
            completion(res.data);
        })
        .then(() => {
            dispatch(decrementProgress());
        })

}

// Update User
export const updateUser = (userComparatorData, userData, history) => dispatch => {
    // console.log('adminActions.updateUser called; id: ' + userData.id);
    console.log('adminActions.updateUser called; value of userData:');
    console.log(userData);

    const url = '/api/users/update/' + userData.id;

    // turn on spinner
    dispatch(incrementProgress());    

    // NEW    

    axios
        // .put(url, userData)
        .put(url, ({userComparatorData: userComparatorData, userData: userData}))


        // .then(res => history.push("/login"))
        .then(() => {
            dispatch(decrementProgress());
        })
        .then(() => {
            if (history) {
                history.push('/admin-panel/view-users');
            }            
        })
        .catch(err => {
            dispatch(decrementProgress());
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        }
        );

}

// Add User
export const addUser = (userData, history) => dispatch => {
    // console.log('adminActions.addUser called; id: ' + userData.id);

    const url = '/api/users/create';

    // turn on spinner
    dispatch(incrementProgress());    

    // NEW    

    axios
        .post(url, userData)
        // .then(res => history.push("/login"))
        .then(() => {
            dispatch(decrementProgress());
        })
        .then(() => {
            history.push('/admin-panel/view-users');
        })
        .catch(err => {
            dispatch(decrementProgress());
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        }
        );

}

// Delete User
export const deleteUser = (id, completion) => dispatch => {
    console.log('adminActions.deleteUser called; id: ' + id);

    // turn on spinner
    dispatch(incrementProgress());

    const url = '/api/users/delete/' + id;
    axios
        .delete(url)
        .then(res => {
            completion(res.data);
        })
        .then(() => {
            dispatch(decrementProgress());
        })

}

// // View Courses
// export const viewCourses = (history) => dispatch => {

//     axios
//         // .post("/api/courses/add", courseData, {
//         //     headers: {
//         //         'Authorization': `Bearer ${localStorage.jwtToken}`
//         //     },
//         // })

//         // .post("/api/courses/view", courseData)
//         .get("/api/courses")

//         // TODO: change this redirect to the (yet to be created) page 'courses' where user can see their new course listed with the others
//         // .then(res => history.push("/"))
//         // .then(history.push("/"))

//         .catch(err =>
//             dispatch({
//                 type: GET_ERRORS,
//                 payload: err.response.data
//             })
//         );
// };

