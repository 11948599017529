import React, { Component } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Collapse, Navbar, NavbarToggler, NavbarBrand, NavbarText, Nav, NavItem, NavLink, /*Dropdown,*/ UncontrolledDropdown, DropdownItem, DropdownToggle, DropdownMenu, Spinner } from 'reactstrap';
import withSizes from 'react-sizes'
import { browserHistory } from 'react-router';
// import { withRouter } from 'react-router';
import uuid from "uuid";

import axios from 'axios';

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";

import SaxIcon from "../../images/sax-icon-yellow.png";

const NavTextColor = "#66AAFF";
const NavBarBackgroundColor = "black";
const SubmenuBackgroundColor = "black";

const renderLogin = () => (
    <Nav navbar>
        <NavItem className="px-2" style={{ width: '60px' }}>

            <NavLink href="/login">Log&nbsp;In</NavLink>
        </NavItem>
        <NavItem className="px-2" style={{ width: '60px' }}>

            <NavLink href="/register">Register</NavLink>
        </NavItem>
    </Nav>
)

class NavigationBar extends Component {

    constructor(props) {
        console.log('NavigationBar.constructor called.');

        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.toggleError = this.toggleError.bind(this);

        this.state = {
            isOpen: false,
            dropdownError: false,
            dropdownPrice: false,
            dropdownVolume: false,

            groupsCollection: []
        };
    }

    componentDidMount() {
        console.log('NavigationBar.componentDidMount called.')

        console.log("\n\nDEBUG: value of this.props.router:")
        console.log(this.props.router)

        const url = global.serverAddress + '/api/groups'

        // axios.get('http://localhost:5000/api/groups')
        axios.get(url)
            .then(res => {
                this.setState({ groupsCollection: res.data });
                console.log('finished GET operation; value of this.state.groupsCollection:' + this.state.groupsCollection);
                this.forceUpdate();
            })
            .catch(function (error) {
                console.log(error);
            })
    }


    renderGroupsDropdown() {

        // const { courses } = this.groupsCollection;
        console.log('running NavigationBar.renderGroupsDropdown.');

        const items = [];

        items.push(
            <DropdownItem className="navLinkText" style={{ color: NavTextColor, backgroundColor: SubmenuBackgroundColor }} /*key={index}*/ onClick={e =>
                this.props.history.push({ pathname: "/whitesell-brothers-quartet" })}>
                Whitesell Brothers Quartet
            </DropdownItem>
        )
        items.push(
            <DropdownItem className="navLinkText" style={{ color: NavTextColor, backgroundColor: SubmenuBackgroundColor }} /*key={index}*/ onClick={e =>
                this.props.history.push({ pathname: "/cannonball-quintet" })}>
                Jake Whitesell's Cannonball Quintet
            </DropdownItem>
        )


        items.push(
            <DropdownItem className="navLinkText" style={{ color: NavTextColor, backgroundColor: SubmenuBackgroundColor }} /*key={index}*/ onClick={e =>
                window.open("https://www.facebook.com/profile.php?id=100064116901311", "_blank") }>
                Chicken Fat Injection
            </DropdownItem>
        )
        items.push(
            <DropdownItem className="navLinkText" style={{ color: NavTextColor, backgroundColor: SubmenuBackgroundColor }} /*key={index}*/ onClick={e =>
                window.open("http://www.guaguavt.com", "_blank") }>
                Guagua
            </DropdownItem>
        )
        items.push(
            <DropdownItem className="navLinkText" style={{ color: NavTextColor, backgroundColor: SubmenuBackgroundColor }} /*key={index}*/ onClick={e =>
                window.open("https://www.kerubomusic.com", "_blank") }>
                Kerubo
            </DropdownItem>
        )
        items.push(
            <DropdownItem className="navLinkText" style={{ color: NavTextColor, backgroundColor: SubmenuBackgroundColor }} /*key={index}*/ onClick={e =>
                window.open("https://www.facebook.com/TheSaturnPeoplesSoundCollective", "_blank") }>
                Saturn People's Sound Collective
            </DropdownItem>
        )
        items.push(
            <DropdownItem className="navLinkText" style={{ color: NavTextColor, backgroundColor: SubmenuBackgroundColor }} /*key={index}*/ onClick={e =>
                window.open("https://www.facebook.com/Soul-porpoise-101822805630098", "_blank") }>
                Soul Porpoise
            </DropdownItem>
        )
        
        console.log('entering renderGroupsDropdown for loop...');
        for (const [index, value] of this.state.groupsCollection.entries()) {
            console.log('index: ' + index + '; value: ' + value);
            const url = '/groups/' + value._id;
            console.log('url: ' + url + '; title: ' + value.name);

            items.push(
                <DropdownItem className="navLinkText" style={{ color: NavTextColor, backgroundColor: SubmenuBackgroundColor }} key={index} onClick={e =>
                    this.props.history.push({ pathname: url, search: '?query=abc', state: { group: value } })}>
                    {value.name}
                </DropdownItem>

            )
            console.log('items: ' + items);
        }

        return (
            <UncontrolledDropdown className="navLinkText nowrap py-0" nav inNavbar >
                <DropdownToggle nav caret style={{ color: NavTextColor }}>BANDS</DropdownToggle>
                {/* <DropdownMenu className="bg-black" style={{ color: NavTextColor, backgroundColor: "black" }} left>*/}
                <DropdownMenu style={{ marginTop: "-1px", marginBottom: "-12px", marginLeft: "-16px", color: NavTextColor, backgroundColor: SubmenuBackgroundColor, textAlign: "left", borderStyle: "solid", borderWidth: "2px", borderRadius: "5px", borderColor: "#212529" }} left>
                    {items}
                </DropdownMenu>
            </UncontrolledDropdown>
        )

    }




    onLogoutClick = e => {
        e.preventDefault();
        this.props.logoutUser();
        // this.redirectToLandingPage();    
    };

    redirectToLandingPage() {
        return (
            <Redirect to="/" />
        )
    }

    renderGreeting(firstName, lastName) {
        const { user } = this.props.auth;

        return (
            // <Nav navbar>

            <UncontrolledDropdown className="navLinkText nowrap py-0" nav inNavbar >
                <DropdownToggle style={{ color: 'white' }} nav caret>{firstName + " " + lastName}</DropdownToggle>
                <DropdownMenu className="bg-dark" left>
                    <DropdownItem className="navLinkText bg-dark" style={{ color: "white", backgroundColor: "black" }} href="/dashboard" onClick={this.clickHandler("/dashboard")}>My Account</DropdownItem>

                    {user.roles.includes('admin') ? (<DropdownItem className="navLinkText bg-dark" style={{ color: "white", backgroundColor: "black" }} href="/admin-panel" onClick={this.clickHandler("/admin-panel")}>Admin Panel</DropdownItem>) : (null)}

                    <DropdownItem divider />
                    <DropdownItem className="navLinkText bg-dark" style={{ color: "white", backgroundColor: "black" }} href="/" onClick={this.onLogoutClick}>Log Out</DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>

            // </Nav>
        );

    }

    toggleNavbar() {
        console.log('NavigationBar.toggleNavbar() called.');
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    toggleError() {
        this.setState({
            dropdownError: !this.state.dropdownError
        });
    }

    clickHandler(redirectTo) {
        console.log('NavigationBar.clickHandler(redirectTo) called; value of redirectTo:' + redirectTo);
    }


    render() {

        console.log('running NavigationBar.render.');

        const navbarClass = this.props.collapseButtonIsShowing ? "mx-auto navbar-right" : "mx-auto navbar-center"
        const navBarBorderStyle = this.props.collapseButtonIsShowing ? "none" : "solid"
        const navBarTextAlign = this.props.collapseButtonIsShowing ? "center" : "center"
        // const navbarHeaderTextClass = this.props.homePage ? "navbarHeaderText" : "navbarHeaderTextReduced"
        const navbarHeaderPadding = this.props.homePage ? "0" : ""

        console.log("DEBUG: value of this.props.collapseButtonIsShowing: " + this.props.collapseButtonIsShowing)

        const { user } = this.props.auth;

        return (

            <div>

                <Navbar dark expand="lg" className="fixed-top py-1" style={{ backgroundColor: NavBarBackgroundColor, height: "60px"}}>

                    <NavbarBrand tag={Link} to="/" /*className="py-0"*/ className={this.props.homePage ? "py-1" : "py-1"}>
                        <div>
                            {/* <h1 className={navbarHeaderTextClass} style={{ color: "white" }}>JAKE WHITESELL</h1> */}
                            {/* <span className="navbarHeaderTextReduced" style={{ fontFamily: "Bowlby One SC" }}>Jake Whitesell</span> */}
                            <span style={{ color: NavTextColor, fontSize: 30, fontFamily: "Bowlby One SC" }}>Jake Whitesell 
                            <img src={SaxIcon} height="32px" style={{ marginLeft: "10px", marginTop: "5px" }} /></span> 
                        </div> 
                    </NavbarBrand>

                    <NavbarToggler onClick={this.toggleNavbar} />
                    
                    <Collapse isOpen={this.state.isOpen} navbar>

                        {/* <Nav className="ml-auto" navbar style={{ position: "relative", top: this.props.homePage ? "-24px" : "-15px", backgroundColor: "black" }}> */}
                        <Nav className="ml-auto py-0" navbar /*style={{marginTop: "-8px", marginBottom: "-8px"}}*/ /*style={{marginTop: "-23px"}}*/ /*style={{ backgroundColor: "red" }}*/ >
                            {/* <Nav className="ml-auto" navbar style={{ backgroundColor: "black" }}> */}

                            {this.props.auth.isAuthenticated ? this.renderGreeting(user.firstName, user.lastName) : <> </>}

                            {/* <NavItem className="navLinkText nowrap py-0" > */}
                            <NavItem className="navLinkText nowrap py-0">                                
                                <NavLink className="nowrap" style={{ color: NavTextColor }} tag={Link} to="/">HOME</NavLink>
                            </NavItem>

                            <NavItem className="navLinkText nowrap py-0" >                                
                                <NavLink className="nowrap" style={{ color: NavTextColor }} tag={Link} to="/about">ABOUT</NavLink>
                            </NavItem>

                            <NavItem className="navLinkText nowrap py-0" >                                
                                <NavLink className="nowrap" style={{ color: NavTextColor }} tag={Link} to="/shows">SHOWS</NavLink>                                
                            </NavItem>

                            <NavItem className="navLinkText nowrap py-0" >                            
                                <NavLink className="nowrap" style={{ color: NavTextColor }} tag={Link} to="/listen">LISTEN</NavLink>
                            </NavItem>

                            <NavItem className="navLinkText nowrap py-0" >                                
                                <NavLink className="nowrap" style={{ color: NavTextColor }} tag={Link} to="/Video">VIDEO</NavLink>
                            </NavItem>

                            <NavItem className="navLinkText nowrap py-0" >                                
                                <NavLink className="nowrap" style={{ color: NavTextColor }} tag={Link} to="/photos">PHOTOS</NavLink>
                            </NavItem>

                            {this.renderGroupsDropdown()}
                            
                            <NavItem className="navLinkText nowrap py-0" >                                
                                <NavLink className="nowrap" style={{ color: NavTextColor }} tag={Link} to="/lessons">LESSONS</NavLink>
                            </NavItem>

                            <NavItem className="navLinkText nowrap py-0" >                                
                                <NavLink className="nowrap" style={{ color: NavTextColor }} tag={Link} to="/contact">CONTACT</NavLink>
                            </NavItem>
                            
                        </Nav>

                    </Collapse>

                    <div class="fadeout" style={{top:60}}></div>

                </Navbar>

                <div style={{height: "80px"}}></div>

            </div >

        );

    }

}



NavigationBar.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    progress: state.progress,
    auth: state.auth
});

const mapSizesToProps = ({ width }) => ({
    collapseButtonIsShowing: width < 1200,
    width: width
})


// old (working)
// export default connect(mapStateToProps, { logoutUser })(NavigationBar);

// new

export default connect(mapStateToProps, { logoutUser })(withRouter(withSizes(mapSizesToProps)(NavigationBar)));
