import React, { Component } from "react";
import Footer from '../../layout/Footer';

import NavigationBarMobile from '../../layout/NavigationBarMobile';
import NavigationBarDesktop from '../../layout/NavigationBarDesktop';

import Helmet from 'react-helmet';
import withSizes from 'react-sizes';

/* import jake3 from '../../../images/jake-3.jpg' */
import CannonballQuintet from "../../../images/CannonballQuintet1-small.jpg";

/* for calender */
import axios from 'axios';
import { Button, ButtonGroup } from 'reactstrap';


var videoDisplayRatio = true; 

const screenRatio = (window.innerWidth / window.innerHeight);

class WBQPage extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = {
            navbarIsOpen: false,
            
            /* for calender */
            eventsCollection: [],
            isViewingPastEvents: false

        }
    }

    toggle() {
        this.setState({
            navbarIsOpen: !this.state.navbarIsOpen
        })
    }


    /* for calender */
    componentDidMount() {
        console.log('CalendarPage.componentDidMount called.')

        const url = global.serverAddress + '/api/events'

        axios.get(url)
            .then(res => {
                this.setState({ eventsCollection: res.data });
                console.log('finished GET operation; value of this.state.eventsCollection:');
                console.log(this.state.eventsCollection)
                this.forceUpdate();
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    renderEvents() {
        console.log("running CalendarPage.renderEvents()")
        const items = [];

        // filter events to show only the selected type (past or future)
        var filteredEvents = this.state.eventsCollection.filter(event => {

            // get the current date/time
            var now = new Date()

            // get the event's start date/time
            var eventStartDate = new Date(event.startDate)

            // get the time of day of the event in seconds by multiplying the event start date's hours (0-23) by 3600 (seconds per hour), adding the sum to the event start date's minutes (0-59) multiplied by 60 (seconds per minute), and adding that to the event start date's seconds (0-59).
            const seconds = (eventStartDate.getHours() * 3600) + (eventStartDate.getMinutes() * 60) + eventStartDate.getSeconds()

            // get the seconds remaining between the event's start time (const seconds) and midnight (86400)
            const offsetToMidnight = 86400 - seconds

            // number of seconds in four hours
            const fourAM = 14400

            // adding the offset to midnight to const fourAM (a distance of four hours, expressed in seconds) yields the number of seconds between the event's start time and its cutoff time (4am the next day)
            const addend = offsetToMidnight + fourAM

            // create a date using this value, which will always be the day following the event's start date
            const cutoffDate = new Date(eventStartDate.getTime() + (addend * 1000))

            // if an event matches this predicate, return it
            if (this.state.isViewingPastEvents) {
                return ((cutoffDate.getTime() <= now.getTime()) && (event.group == "Jake Whitesell's Cannonball Quintet"))
            } else {
                return ((cutoffDate.getTime() > now.getTime()) && (event.group == "Jake Whitesell's Cannonball Quintet"))
            } 
        })

        if (this.state.isViewingPastEvents) {
            filteredEvents.sort((a, b) => (a.startDate > b.startDate) ? -1 : 1 )
        } else {
            filteredEvents.sort((a, b) => (a.startDate > b.startDate) ? 1 : -1 )
        }
        

        console.log("entering for loop...")

        for (const [index, event] of filteredEvents.entries()) {

            const startDateObject = new Date(event.startDate)
            const weekdayInt = startDateObject.getDay()
            var weekday = ""

            switch (weekdayInt) {
                case 0: {
                    weekday = "Sunday"
                    break
                }
                case 1: {
                    weekday = "Monday"
                    break
                }
                case 2: {
                    weekday = "Tuesday"
                    break
                }
                case 3: {
                    weekday = "Wednesday"
                    break
                }
                case 4: {
                    weekday = "Thursday"
                    break
                }
                case 5: {
                    weekday = "Friday"
                    break
                }
                case 6: {
                    weekday = "Saturday"
                    break
                }
            }

            const monthInt = startDateObject.getMonth()
            var month = ""
            switch (monthInt) {
                case 0: {
                    month = "January"
                    break
                }
                case 1: {
                    month = "February"
                    break
                }
                case 2: {
                    month = "March"
                    break
                }
                case 3: {
                    month = "April"
                    break
                }
                case 4: {
                    month = "May"
                    break
                }
                case 5: {
                    month = "June"
                    break
                }
                case 6: {
                    month = "July"
                    break
                }
                case 7: {
                    month = "August"
                    break
                }
                case 8: {
                    month = "September"
                    break
                }
                case 9: {
                    month = "October"
                    break
                }
                case 10: {
                    month = "November"
                    break
                }
                case 11: {
                    month = "December"
                    break
                }
            }

            const startDateString = weekday + ", " + month + " " + startDateObject.getDate() + ", " + startDateObject.getFullYear()

            items.push(

                <p className="customText" style={{ lineHeight: "1.3", color: "white" }}>
                    {event.timeText == "" ? <>{startDateString}<br /></> : <>{startDateString + ", " + event.timeText}<br /></>}
                    {event.title == "" ? <> </> : <>{event.title}<br /></>}
                    {/* {event.groupLink == "" ? <>{event.group}<br /></> : <><a href={event.groupLink}>{event.group}</a><br /></>} */}
                    {event.venueLink == "" ? <>{event.venue}{(event.location == "" || event.location == undefined) ? <> </> : <> ({event.location})</>}<br /></> : <><a href={event.venueLink}>{event.venue}</a>{(event.location == "" || event.location == undefined) ? <> </> : <> ({event.location})</>}<br /></>}
                    {event.ticketsLink == "" ? <> </> : <b>Tickets: <a href={event.ticketsLink}>{event.ticketsLink}</a><br /></b>}
                    {/* {event.description == "" ? <> </> : <>{event.description}<br /></>} */}
                    <div style={{ height: "10px" }}></div>
                </p>
                
            )
        }
        return (
            <div>
                {items}
            </div>
        )
    } 


    render() {

        const paragraphMargin = this.props.collapseButtonIsShowing ? "0px" : "20px"

        return (
            <div>
                <Helmet>
                    <title>{"Jake Whitesell's Cannonball Quintet"}</title>
                    <body className={"normal"} />
                </Helmet>
                {window.innerWidth < 1050 ?
                    <NavigationBarMobile />
                    :
                    <NavigationBarDesktop />
                }

                <div className="row">
                    <div className="col-lg-12">
                        <p className="customHeader" style={{ textAlign: 'center'}}>
                            Jake Whitesell's 
                            { window.innerWidth < 1050 ? <br/> : " "}
                            Cannonball Quintet
                         
                            {/* <br/> 
                            <br/>
                             <img className="img-fluid" style={{ width : "400px"}} src={jake3} /> */}

                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8" style={this.props.width < 1150 ? { textAlign: "center" } : {}}>

                                      
                        <div className="row">

                            <div className="col-xl-2"></div>

                            <div className="col-xl-8">

                                <div width="100" height="0"></div>

                                {this.props.width < 1150 ? <div style={{ height: "16px" }}></div>  : <b></b> }
                        
                                    <img className="img-fluid" style={{ width: "100%", marginBottom: "16px" }} src={CannonballQuintet} />
                                    <br/>
                                    <br/>
                                
                                    <p className="customText" style={{ textAlign: "justify" }}>
                                        Alto saxophonist <a href="./about">Jake Whitesell</a> pays tribute to the Cannonball Adderley Quintet with top Burlington jazz musicians <a href="https://connoryoungmusic.com" target="_blank">Connor Young</a> (trumpet), <a href="https://www.hartiganmusic.com" target="_blank">Mike Hartigan</a> (piano), John Rivers (bass), and Geza Carr (drums). The group presents a variety of fun and exciting music from the repertoire of renowned hard-bop alto saxophonist Julian "Cannonball" Adderley's classic quintet, with compositions by Cannonball, his brother and band-mate, cornetist Nat Adderley, and other collaborators such as pianists Joe Zawinul, Bobby Timmons and Victor Feldman. The Cannonball Adderley Quintet helped popularize "soul jazz", with music rooted in blues, gospel, bebop and soul/funk. 
                                    </p>
                        
                                    {/* calender */}
                                    <br/>
                                            <p className="customText" style={{ color:"#66AAFF", textAlign: "center" }}>
                                                <b>SHOWS:</b> <br/>
                                            <div style={{ height: "10px" }}></div>    
                                            <ButtonGroup>
                                                <Button color="primary" onClick={e => this.setState({ isViewingPastEvents: true })} active={this.state.isViewingPastEvents == true}>Past Shows</Button>
                                                <Button color="primary" onClick={e => this.setState({ isViewingPastEvents: false })} active={this.state.isViewingPastEvents == false}>Upcoming Shows</Button>
                                            </ButtonGroup>
                                            </p>
                                    <div style={{ height: "32px" }}></div>
                                    {this.renderEvents()}

                                    {/* video */}
                                    <font style={{fontSize: "0px"}}>
                                        {videoDisplayRatio = (window.innerWidth < window.innerHeight) ? "75%" : ( (window.innerWidth < 900) ? "50%" /* ((((window.innerHeight - 60) / (window.innerWidth - 20)) * 100) + "%") */ : "75%")}
                                    </font>

                                    <p className="customTextSmall1" style={{ marginBottom: "5px", lineHeight: "1.3", textAlign: "left" }}>
                                        "Introduction to a Samba" <font style={{fontSize: "15px"}}>(by Cannonball Adderley)</font>
                                    </p>
                                    <div style={{position : "relative", paddingTop : videoDisplayRatio}}>
                                        <iframe className="videoWrapperIframe" src="https://www.youtube.com/embed/lYKZYdQ6HDA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div><br/><br/><br/>

                                    <p className="customTextSmall1" style={{ marginBottom: "5px", lineHeight: "1.3", textAlign: "left" }}>
                                        "This Here" <font style={{fontSize: "15px"}}>(by Bobby Timmons)</font>
                                    </p>
                                    <div style={{position : "relative", paddingTop : videoDisplayRatio}}>
                                        <iframe className="videoWrapperIframe" src="https://www.youtube.com/embed/doy_8rhi3Ug" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div><br/><br/><br/>

                                    <p className="customTextSmall1" style={{ marginBottom: "5px", lineHeight: "1.3", textAlign: "left" }}>
                                        "The Chant" <font style={{fontSize: "15px"}}>(by Victor Feldman)</font>
                                    </p>
                                    <div style={{position : "relative", paddingTop : videoDisplayRatio}}>
                                        <iframe className="videoWrapperIframe" src="https://www.youtube.com/embed/_SVMefiLOso" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                                    
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                    </div>
                    <div className="col-md-2"></div>
                </div>

                <Footer />
            </div> 
        );
    }
}

const mapSizesToProps = ({ width }) => ({
    collapseButtonIsShowing: width < 1200,
    width: width
})

export default (withSizes(mapSizesToProps)(WBQPage))
