
import React, { Component } from "react";
import Helmet from 'react-helmet';
import withSizes from 'react-sizes'
import axios from 'axios';

import PhotoGallery from './PhotoGallery';

import { connect } from "react-redux";

import { updateCurrentUser } from "../../actions/authActions";

import Footer from '../layout/Footer';

import NavigationBarMobile from '../layout/NavigationBarMobile';
import NavigationBarDesktop from '../layout/NavigationBarDesktop';


class GroupDetail extends Component {

    constructor(props) {
        super(props);

        this.renderPhotos = this.renderPhotos.bind(this)

        console.log('running GroupDetail.constructor.');
        console.log('value of JSON.stringify(this.props.auth): ' + JSON.stringify(this.props.auth));

        this.state = {
            group: this.props.location.state.group,
            id: "",
            images: []
        }

    }

    componentWillReceiveProps(nextProps) {
        console.log("GroupDetail.componentWillReceiveProps called.")
        if (nextProps.match.params.id !== this.state.id) {
            console.log("(nextProps.params._id !== this.props.params._id), calling forceUpdate.")
            this.setState({ id: nextProps.match.params.id })
            window.location.reload()
        } else {
            console.log("(nextProps.params._id == this.props.params._id), NOT calling forceUpdate.")
        }
    }


    componentDidMount() {
        console.log('GroupDetail.componentDidMount called.')

        const url = global.serverAddress + '/api/groups/edit/' + this.state.group._id;

        axios.get(url)
            .then(res => {
                console.log('reached second then block; value of res.data: ');
                console.log(res.data);
                var imagesArray = []

                console.log("running Object.entries(res.data.images).map...")

                Object.entries(res.data.images).map(([key, value]) => {
                    // Pretty straightforward - use key for the key and value for the value.
                    // Just to clarify: unlike object destructuring, the parameter names don't matter here.

                    var decodeBase64 = function (s) {
                        var e = {}, i, b = 0, c, x, l = 0, a, r = '', w = String.fromCharCode, L = s.length;
                        var A = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
                        for (i = 0; i < 64; i++) { e[A.charAt(i)] = i; }
                        for (x = 0; x < L; x++) {
                            c = e[s.charAt(x)]; b = (b << 6) + c; l += 6;
                            while (l >= 8) { ((a = (b >>> (l -= 8)) & 0xff) || (x < (L - 2))) && (r += w(a)); }
                        }
                        return r;
                    };

                    var replaced = value.replace(/^[^,]+,/, '');
                    var byteString = decodeBase64(replaced);
                    var buffer = new ArrayBuffer(byteString.length);
                    var intArray = new Uint8Array(buffer);
                    for (var i = 0; i < byteString.length; i++) {
                        intArray[i] = byteString.charCodeAt(i);
                    }
                    let image = new Blob([buffer], { type: "image/png" });

                    const imageURL = URL.createObjectURL(image)

                    var newImage = new Image(imageURL)

                    // const imageObject = {
                    //     src: imageURL,
                    //     width: newImage.width,
                    //     height: newImage.height,
                    //     title: "title"
                    // }

                    const imageObject = imageURL

                    imagesArray.push(imageObject)

                })

                console.log("map function finished; value of imagesArray:")
                console.log(imagesArray)

                this.setState({
                    group: res.data,
                    images: imagesArray
                })
            })
            .catch(function (error) {
                console.log('reached catch block.');
                console.log(error);
            })
    }

    renderPhotos() {
        var returnBlock = []

        var index;

        for (index = 0; index < this.state.images.length; index++) {
            console.log("value of image:")
            console.log(this.state.images[index])

            returnBlock.push(
                <div>
                    <div className="row">
                        <div className="col-md-2"></div>

                        <div className="col-md-8" style={{ textAlign: "center" }}>
                            <img className="img-fluid" src={this.state.images[index]} />
                        </div>

                        <div className="col-md-2"></div>
                    </div>

                    {(index < (this.state.images.length - 1)) ?
                        <div style={{ height: "32px" }}></div>
                        :
                        <></>
                    }
                </div>
            )

        }

        return (
            <>
                { returnBlock}
            </>
        )
    }

    render() {

        console.log('Running GroupDetail.render().');
        console.log("value of this.state.group:")
        console.log(this.state.group)

        const photos = this.state.group.images !== undefined ? this.state.group.images : []

        return (
            <div>
                <Helmet>
                    <title>{"Jake Whitesell – " + this.state.group.name}</title>
                    <body className={"normal"} />
                </Helmet>

                {window.innerWidth < 1050 ?
                    <NavigationBarMobile />
                    :
                    <NavigationBarDesktop />
                }
                <div className="container">

                    <div className="row">
                        <div className="col-lg-12">
                            <p className="customHeader" style={{ textAlign: 'center' }}>
                                {this.state.group.name}
                            </p>
                        </div>
                    </div>

                    <div style={{ height: "25px" }}></div>

                    {this.renderPhotos()}

                    <div style={{ height: "32px" }}></div>

                    <div className="row">
                        <div className="col-lg-12">

                            {/* <h2 className="customText text-center" style={{ color: "white" }}>
                                {this.state.group.name}
                            </h2> */}

                            <p className="customText" style={{ color: "white" }}>
                                {this.state.group.description}
                            </p>

                        </div>
                    </div>


                    <Footer />
                </div>
            </div>


        );
    }
}


const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

const mapSizesToProps = ({ width }) => ({
    collapseButtonIsShowing: width < 1200,
    width: width
})

export default connect(mapStateToProps, { updateCurrentUser })(withSizes(mapSizesToProps)(GroupDetail));