import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { deleteGroup } from "../../actions/adminActions";
import { Button } from 'reactstrap';

class GroupDataTable extends Component {

    constructor(props) {
        super(props);
        console.log('GroupDataTable.constructor called.');
        console.log('value of this.props.deleteGroup: ' + this.props.deleteGroup);
        // console.log('value of this.props.bogus: ' + this.props.bogus);

        this.state = {
            mode: this.props.mode // 0 = 'view groups' mode
        };
    }

    render() {

        const obj = this.props.obj;

        return (
            <tr style={{color: "white"}}>
                <td>
                    {this.props.obj._id}
                </td>
                <td>
                    {this.props.obj.name}
                </td>
                <td>
                    {this.props.obj.description}
                </td>
                <td>
                    <Button color="danger" /*outline*/ onClick={e =>
                        window.confirm("Are you sure you want to delete this group?") &&
                        this.props.deleteGroupFunction(obj._id)
                    }>
                        Delete
                    </Button>
                </td>
                <td>
                    <Button color="primary" /*outline*/ onClick={() => { this.props.editGroupFunction(obj._id) }}>
                        Edit
                    </Button>
                </td>

            </tr>
        );

    }
}

GroupDataTable.propTypes = {
    deleteGroup: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { deleteGroup }
)(GroupDataTable);

// export default DataTable;
