
import React, { Component, /*useRef*/ } from "react";
import { /*Link,*/ withRouter } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Helmet from 'react-helmet';
import withSizes from 'react-sizes'
import DatePicker from 'react-datepicker'
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';

// import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateEvent } from "../../actions/adminActions";
// import classnames from "classnames";
import Footer from "../layout/Footer";

import NavigationBarMobile from '../layout/NavigationBarMobile'
import NavigationBarDesktop from '../layout/NavigationBarDesktop'

import EventDataTable from "./EventDataTable";
// import { unregisterUserForCourse } from "../../actions/adminActions";
// import { registerUserForCourse } from "../../actions/adminActions";
import { updateCurrentUser } from '../../actions/authActions';
import { updateUser } from '../../actions/adminActions';
import axios from 'axios';
import arraysAreEqual from '../../utils/helperMethods';

import "react-datepicker/dist/react-datepicker.css";


class EditEvent extends Component {

    constructor(props) {
        super(props);
        const event = this.props.location.state.event;

        this.updateState = this.updateState.bind(this);
        this.toggle = this.toggle.bind(this);
        this.fieldsHaveChanged = this.fieldsHaveChanged.bind(this);

        this.state = {
            event: {
                id: event._id,
                group: event.group,
                groupLink: event.groupLink,
                venue: event.venue,
                venueLink: event.venueLink,
                location: event.location,
                title: event.title,
                description: event.description,
                startDate: new Date(event.startDate),
                timeText: event.timeText,
                ticketsLink: event.ticketsLink,
            },

            errors: {},

            eventsCollection: [],
            popupIsOpen: false,
            didFetchUsers: false
        };
        console.log(this.props);

        this.eventComparator = {};

    }


    componentDidMount() {
        this.eventComparator = {
            group: this.state.event.group,
            groupLink: this.state.event.groupLink,
            venue: this.state.event.venue,
            venueLink: this.state.event.venueLink,
            location: this.state.event.location,
            title: this.state.event.title,
            description: this.state.event.description,
            startDate: new Date(this.state.event.startDate),
            timeText: this.state.event.timeText,
            ticketsLink: this.state.event.ticketsLink
        }

        // console.log('\n\nDEBUG: value of this.courseComparator at end of componentDidMount:');
        console.log(this.eventComparator);
    }

    onChange({ target: { id, value } }, type) {
        this.setState({
            event: {
                ...this.state.event,
                [id]: value
            }
        });
    }

    beforeSubmitValidation = e => {
        e.persist()
    }


    onSubmit = e => {
        console.log('running onSubmit.');

        e.preventDefault();

        const errors = this.state.errors;

        this.props.updateEvent(this.eventComparator, this.state.event, this.props.history);

    };

    fieldsHaveChanged() {

        if ((this.state.event.group !== this.eventComparator.group) || (this.state.event.groupLink !== this.eventComparator.groupLink) || (this.state.event.venue !== this.eventComparator.venue) || (this.state.event.venueLink !== this.eventComparator.venueLink) || (this.state.event.location !== this.eventComparator.location) || (this.state.event.title !== this.eventComparator.title) || (this.state.event.description !== this.eventComparator.description) || (this.state.event.startDate.toISOString() !== this.eventComparator.startDate.toISOString()) || (this.state.event.timeText !== this.eventComparator.timeText) || (this.state.event.ticketsLink !== this.eventComparator.ticketsLink)) {
            return true;
        } else {
            return false;
        }
    }


    confirmDiscard() {
        // console.log('running EditCourse.confirmDiscard().');
        window.confirm("Are you sure you want to go back? Any unsaved changes will be lost.") && this.props.history.goBack()
    }

    dataTable(collection, mode) {
        // return this.state.course.registrants.map((data, i) => {
        return collection.map((data, i) => {
            // console.log('creating data table for user number ' + i)
            return <EventDataTable mode={mode} obj={data} key={i} group={this.state.event} />;
        });
    }


    updateState(updatedUserData, updatedEventData) {

        console.log('EditEvent.updateState called.');

        this.setState({
            id: updatedEventData._id,
            group: updatedEventData.group,
            groupLink: updatedEventData.groupLink,
            venue: updatedEventData.venue,
            venueLink: updatedEventData.venueLink,
            location: updatedEventData.location,
            title: updatedEventData.title,
            description: updatedEventData.description,
            startDate: updatedEventData.startDate,
            timeText: updatedEventData.timeText,
            ticketsLink: updatedEventData.ticketsLink,
        })

        if (updatedUserData._id === this.props.auth.user.id) {
            // console.log('updatedUserData.id === this.props.auth.user.id; calling this.props.updateCurrentUser.');
            this.props.updateCurrentUser(updatedUserData)
        } else {
            // console.log('updatedUserData.id !== this.props.auth.user.id; values:');
            // console.log('updatedUserData.id: ' + updatedUserData.id);
            // console.log('this.props.auth.user.id: ' + this.props.auth.user.id);
        }

    }

    toggle() {

        this.setState({
            popupIsOpen: !this.state.popupIsOpen
        })

        // if (this.state.didFetchUsers === false) {
        //     const url = global.serverAddress + '/api/users';

        //     axios.get(url)
        //         .then(res => {
        //             this.setState({ usersCollection: res.data.filter(user => { return !this.state.group.registrants.map(registrant => { return registrant._id }).includes(user._id) }) });
        //             this.setState({
        //                 didFetchUsers: true,
        //                 popupIsOpen: !this.state.popupIsOpen
        //             })
        //         })
        //         .catch(function (error) {
        //             console.log(error);
        //         })
        // } else {
        //     this.setState({
        //         popupIsOpen: !this.state.popupIsOpen
        //     })
        // }

    }


    render() {
        // const { errors } = this.state;
        // const user = this.props.location.state.user;        

        console.log('Running EditEvent.render().');

        const ExampleCustomInput = ({ value, onClick }) => (
            <button className="example-custom-input" onClick={onClick}>
                {value}
            </button>
        );

        return (
            <div>
                <Helmet bodyAttributes={{ style: "background-color: #000000" }}>
                    <title>{"Jake Whitesell – Edit Event"}</title>
                </Helmet>
                {this.props.collapseButtonIsShowing ?
                    <NavigationBarMobile />
                    :
                    <NavigationBarDesktop />
                }

                <div className="container">

                    <div style={{ height: "128px" }}></div>

                    <div className="row">
                        <div className="col-lg-12">
                            <Button color="primary" /*outline*/ onClick={e => this.fieldsHaveChanged() ? this.confirmDiscard() : this.props.history.goBack()}>
                                Back
                            </Button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">

                            <div className="row">
                                <div className="col-lg-12">
                                    <p className="customHeader" style={{ textAlign: 'center' }}>
                                        Edit Event
                                    </p>
                                </div>
                            </div>

                            {/* NEW */}

                            {/* <form noValidate onSubmit={this.onSubmit} id="editEventForm"> */}
                            <AvForm onValidSubmit={this.onSubmit} beforeSubmitValidation={this.beforeSubmitValidation} id="editEventForm">

                                <div className="form-group" style={{ paddingBottom: '15px' }}>
                                    <label style={{ color: "white" }} htmlFor="title">Title</label>
                                    <input
                                        onChange={e => this.onChange(e, 'title')}
                                        value={this.state.event.title}
                                        placeholder="Title"
                                        // error={errors.title}
                                        id="title"
                                        type="title"
                                        // className={classnames("form-control", {
                                        //     invalid: errors.title
                                        // })}
                                        className="form-control"
                                    />
                                    {/* <p className="text-danger float-right">{errors.title}</p> */}
                                </div>

                                <div className="form-row">
                                    <AvGroup className="form-group col-md-6" style={{ paddingBottom: '15px' }}>
                                        <label style={{ color: "white" }} htmlFor="group">Group</label>
                                        <AvInput
                                            onChange={e => this.onChange(e, 'group')}
                                            value={this.state.event.group}
                                            placeholder="Group"
                                            name="group"
                                            required
                                            // error={errors.group}
                                            id="group"
                                            type="group"
                                            // className={classnames("form-control", {
                                            //     invalid: errors.group
                                            // })}
                                            className="form-control"
                                        />
                                        <AvFeedback>Group is required.</AvFeedback>
                                        {/* <p className="text-danger float-right">{errors.group}</p> */}
                                    </AvGroup>

                                    <div className="form-group col-md-6" style={{ paddingBottom: '15px' }}>
                                        <label style={{ color: "white" }} htmlFor="groupLink">Group Link</label>
                                        <input
                                            onChange={e => this.onChange(e, 'groupLink')}
                                            value={this.state.event.groupLink}
                                            placeholder="Group Link"
                                            // error={errors.groupLink}
                                            id="groupLink"
                                            type="groupLink"
                                            // className={classnames("form-control", {
                                            //     invalid: errors.groupLink
                                            // })}
                                            className="form-control"
                                        />
                                        {/* <p className="text-danger float-right">{errors.groupLink}</p> */}
                                    </div>


                                </div>

                                <div className="form-row">
                                    <AvGroup className="form-group col-md-6" style={{ paddingBottom: '15px' }}>
                                        <label style={{ color: "white" }} htmlFor="venue">Venue</label>
                                        <AvInput
                                            onChange={e => this.onChange(e, 'venue')}
                                            value={this.state.event.venue}
                                            placeholder="Venue"
                                            name="venue"
                                            required
                                            // error={errors.venue}
                                            id="venue"
                                            type="venue"
                                            // className={classnames("form-control", {
                                            //     invalid: errors.venue
                                            // })}
                                            className="form-control"
                                        />
                                        <AvFeedback>Venue is required.</AvFeedback>
                                        {/* <p className="text-danger float-right">{errors.venue}</p> */}
                                    </AvGroup>

                                    <div className="form-group col-md-6" style={{ paddingBottom: '15px' }}>
                                        <label style={{ color: "white" }} htmlFor="venueLink">Venue Link</label>
                                        <input
                                            onChange={e => this.onChange(e, 'venueLink')}
                                            value={this.state.event.venueLink}
                                            placeholder="Venue Link"
                                            // error={errors.venueLink}
                                            id="venueLink"
                                            type="venueLink"
                                            // className={classnames("form-control", {
                                            //     invalid: errors.venueLink
                                            // })}
                                            className="form-control"
                                        />
                                        {/* <p className="text-danger float-right">{errors.venueLink}</p> */}
                                    </div>

                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-6" style={{ paddingBottom: '15px' }}>
                                        <label style={{ color: "white" }} htmlFor="ticketsLink">Location</label>
                                        <input
                                            onChange={e => this.onChange(e, 'location')}
                                            value={this.state.event.location}
                                            placeholder="Location"
                                            // error={errors.location}
                                            id="location"
                                            type="location"
                                            // className={classnames("form-control", {
                                            //     invalid: errors.location
                                            // })}
                                            className="form-control"
                                        />
                                        {/* <p className="text-danger float-right">{errors.location}</p> */}
                                    </div>

                                    <div className="form-group col-md-6" style={{ paddingBottom: '15px' }}>
                                        <label style={{ color: "white" }} htmlFor="ticketsLink">Tickets Link</label>
                                        <input
                                            onChange={e => this.onChange(e, 'ticketsLink')}
                                            value={this.state.event.ticketsLink}
                                            placeholder="Tickets Link"
                                            // error={errors.ticketsLink}
                                            id="ticketsLink"
                                            type="ticketsLink"
                                            // className={classnames("form-control", {
                                            //     invalid: errors.ticketsLink
                                            // })}
                                            className="form-control"
                                        />
                                        {/* <p className="text-danger float-right">{errors.ticketsLink}</p> */}
                                    </div>

                                </div>

                                <div className="form-row">

                                    <div className="form-group col-md-6" style={{ paddingBottom: '15px' }}>
                                        <label style={{ color: "white" }} htmlFor="startDate">Start Date & Time</label>
                                        <br />
                                        <DatePicker
                                            selected={this.state.event.startDate}
                                            // onChange={value => this.setState({ startDate: value })}
                                            // onChange={e => this.onChange(e, 'startDate')}
                                            onChange={value => this.setState(prevState => ({
                                                event: {
                                                    ...prevState.event,
                                                    startDate: value
                                                }
                                            }))}
                                            // customInput={<ExampleCustomInput />}
                                            showTimeSelect
                                            timeFormat="p"
                                            timeIntervals={15}
                                            timeCaption="time"
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            locale="en"

                                        />
                                    </div>

                                    <div className="form-group col-md-6" style={{ paddingBottom: '15px' }}>
                                        <label style={{ color: "white" }} htmlFor="timeText">Time Text</label>
                                        <input
                                            onChange={e => this.onChange(e, 'timeText')}
                                            value={this.state.event.timeText}
                                            placeholder="Time Text"
                                            // error={errors.timeText}
                                            id="timeText"
                                            type="timeText"
                                            // className={classnames("form-control", {
                                            //     invalid: errors.timeText
                                            // })}
                                            className="form-control"
                                        />
                                        {/* <p className="text-danger float-right">{errors.timeText}</p> */}
                                    </div>

                                </div>

                                <div className="form-group">
                                    <label style={{ color: "white" }} htmlFor="description">Description</label>
                                    <textarea
                                        onChange={e => this.onChange(e, 'description')}
                                        value={this.state.event.description}
                                        placeholder="Description"                                        
                                        // error={errors.description}
                                        name="description"
                                        id="description"
                                        type="description"
                                        placeholder="Description"
                                        required
                                        // className={classnames("form-control", {
                                        //     invalid: errors.description
                                        // })}
                                        style={{ height: '30vh' }}
                                        className="form-control"
                                    />
                                    {/* <p className="text-danger float-right">{errors.description}</p> */}                                    
                                </div>

                            </AvForm>
                            
                        </div>
                    </div>

                    <div style={{ height: '5vh' }}></div>

                    <div className="row">
                        <div className="col-lg-12">
                            <Button type="submit" form="editEventForm" color="primary" /*outline*/ className="float-right">
                                Save
                            </Button>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

const mapSizesToProps = ({ width }) => ({
    collapseButtonIsShowing: width < 1200,
    width: width
})

export default connect(mapStateToProps, { updateEvent, updateUser, updateCurrentUser })(withRouter(withSizes(mapSizesToProps)(EditEvent)));