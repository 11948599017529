import React, { Component } from "react";
import { /*Link,*/ withRouter } from "react-router-dom";
import { Button } from 'reactstrap';
import Helmet from 'react-helmet';
import PropTypes from "prop-types";
import DatePicker from 'react-datepicker'
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import withSizes from 'react-sizes'


import { connect } from "react-redux";
import { addEvent } from "../../actions/adminActions";
import classnames from "classnames";
import Footer from "../layout/Footer";
import NavigationBarMobile from '../layout/NavigationBarMobile'
import NavigationBarDesktop from '../layout/NavigationBarDesktop'

import "react-datepicker/dist/react-datepicker.css";


class AddEvent extends Component {
    constructor(props) {
        super(props);

        this.beforeSubmitValidation = this.beforeSubmitValidation.bind(this)

        this.state = {
            group: "",
            groupLink: "",
            venue: "",
            venueLink: "",
            location: "",
            title: "",
            description: "",
            startDate: new Date(),
            timeText: "",
            ticketsLink: "",

            errors: {},

            groupComparator: "",
            groupLinkComparator: "",
            venueComparator: "",
            venueLinkComparator: "",
            locationComparator: "",
            titleComparator: "",
            descriptionComparator: "",
            startDateComparator: Date,
            timeTextComparator: "",
            ticketsLinkComparator: "",
        };
        console.log(this.props);

        this.eventComparator = {
            group: this.state.group,
            groupLink: this.state.groupLink,
            venue: this.state.venue,
            venueLink: this.state.venueLink,
            location: this.state.location,
            title: this.state.title,
            description: this.state.description,
            startDate: this.state.startDate,
            timeText: this.state.timeText,
            ticketsLink: this.state.ticketsLink,
        }
    }

    //   componentDidMount() {

    //     // If logged in and user navigates to Login page, should redirect them to dashboard
    //     if (this.props.auth.isAuthenticated) {
    //       this.props.history.push("/");
    //     }
    //   }

    componentWillReceiveProps(nextProps) {
        // if (nextProps.auth.isAuthenticated) {
        //     this.props.history.push("/");
        // }

        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }

    // Need to add methods from AddUser.js here

    fieldsHaveChanged() {
        if ((this.state.group !== this.eventComparator.group) || (this.state.groupLink !== this.eventComparator.groupLink) || (this.state.venue !== this.eventComparator.venue) || (this.state.venueLink !== this.eventComparator.venueLink) || (this.state.location !== this.eventComparator.location) || (this.state.title !== this.eventComparator.title) || (this.state.description !== this.eventComparator.description) || (this.state.startDate !== this.eventComparator.startDate) || (this.state.timeText !== this.eventComparator.timeText) || (this.state.ticketsLink !== this.eventComparator.ticketsLink)) {
            return true;
        } else {
            return false;
        }
    }

    confirmDiscard() {
        console.log('running AddEvent.confirmDiscard().');
        window.confirm("Are you sure you want to go back? Any unsaved changes will be lost.") && this.props.history.goBack()
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    beforeSubmitValidation = e => {
        e.persist()
    }

    onSubmit = e => {
        console.log("AddEvent.onSubmit called.")
        console.log("DEBUG: value of this.props.history:")
        console.log(this.props.history)

        // console.log("executing this.props.history.push('/admin-panel/view-events').")
        // this.props.history.push('/admin-panel/view-events')

        e.preventDefault();

        const eventData = {
            group: this.state.group,
            groupLink: this.state.groupLink,
            venue: this.state.venue,
            venueLink: this.state.venueLink,
            location: this.state.location,
            title: this.state.title,
            description: this.state.description,
            startDate: this.state.startDate,
            timeText: this.state.timeText,
            ticketsLink: this.state.ticketsLink
        };

        // this.props.addEvent(eventData, (this.props.history.push('/admin-panel/view-events')));

        this.props.addEvent(eventData, this.props.history);
        
    };


    render() {
        const { errors } = this.state;

        // const ExampleCustomInput = ({ value, onClick }) => (
        //     <button className="example-custom-input" onClick={onClick}>
        //         {value}
        //     </button>
        // );

        const ExampleCustomInput = ({value}) => (
            <button className="example-custom-input" onClick={this.test}>
                {value}
            </button>
        );

        return (
            <div>
                <Helmet bodyAttributes={{ style: "background-color: #000000" }}>
                    <title>{"Jake Whitesell – Add Event"}</title>
                </Helmet>
                {this.props.collapseButtonIsShowing ?
                    <NavigationBarMobile />
                    :
                    <NavigationBarDesktop />
                }

                <div className="container">

                    <div style={{ height: "128px" }}></div>

                    <div className="row">
                        <div className="col-lg-12">
                            <Button color="primary" /*outline*/ onClick={e => this.fieldsHaveChanged() ? this.confirmDiscard() : this.props.history.goBack()}>
                                Back
                            </Button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">

                            <div className="row">
                                <div className="col-lg-12">
                                    <p className="customHeader" style={{ textAlign: 'center' }}>
                                        New Event
                                    </p>
                                </div>
                            </div>

                            <AvForm onValidSubmit={this.onSubmit} beforeSubmitValidation={this.beforeSubmitValidation} id="addEventForm">

                                <div className="form-group" style={{ paddingBottom: '15px' }}>
                                    <label style={{ color: "white" }} htmlFor="title">Title</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.title}
                                        placeholder="Title"
                                        error={errors.title}
                                        id="title"
                                        type="title"
                                        className={classnames("form-control", {
                                            invalid: errors.title
                                        })}
                                    />
                                    <p className="text-danger float-right">{errors.title}</p>
                                </div>

                                <div className="form-row">
                                    <AvGroup className="form-group col-md-6" style={{ paddingBottom: '15px' }}>
                                        <label style={{ color: "white" }} htmlFor="group">Group</label>
                                        <AvInput
                                            onChange={this.onChange}
                                            name="group"
                                            placeholder="Group"
                                            required
                                            value={this.state.group}
                                            error={errors.group}
                                            id="group"
                                            type="group"
                                            className={classnames("form-control", {
                                                invalid: errors.group
                                            })}
                                        />
                                        <AvFeedback>Group is required.</AvFeedback>
                                        <p className="text-danger float-right">{errors.group}</p>
                                    </AvGroup>

                                    <div className="form-group col-md-6" style={{ paddingBottom: '15px' }}>
                                        <label style={{ color: "white" }} htmlFor="groupLink">Group Link</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.groupLink}
                                            placeholder="Group Link"
                                            error={errors.groupLink}
                                            id="groupLink"
                                            type="groupLink"
                                            className={classnames("form-control", {
                                                invalid: errors.groupLink
                                            })}
                                        />
                                        <p className="text-danger float-right">{errors.groupLink}</p>
                                    </div>


                                </div>

                                <div className="form-row">
                                    <AvGroup className="form-group col-md-6" style={{ paddingBottom: '15px' }}>
                                        <label style={{ color: "white" }} htmlFor="venue">Venue</label>
                                        <AvInput
                                            onChange={this.onChange}
                                            name="venue"
                                            placeholder="Venue"
                                            required
                                            value={this.state.venue}
                                            error={errors.venue}
                                            id="venue"
                                            type="venue"
                                            className={classnames("form-control", {
                                                invalid: errors.venue
                                            })}
                                        />
                                        <AvFeedback>Venue is required.</AvFeedback>
                                        <p className="text-danger float-right">{errors.venue}</p>
                                    </AvGroup>

                                    <div className="form-group col-md-6" style={{ paddingBottom: '15px' }}>
                                        <label style={{ color: "white" }} htmlFor="venueLink">Venue Link</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.venueLink}
                                            placeholder="Venue Link"
                                            error={errors.venueLink}
                                            id="venueLink"
                                            type="venueLink"
                                            className={classnames("form-control", {
                                                invalid: errors.venueLink
                                            })}
                                        />
                                        <p className="text-danger float-right">{errors.venueLink}</p>
                                    </div>

                                </div>

                                <div className="form-row">

                                    <div className="form-group col-md-6" style={{ paddingBottom: '15px' }}>
                                        <label style={{ color: "white" }} htmlFor="ticketsLink">Location</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.location}
                                            placeholder="Location"
                                            error={errors.location}
                                            id="location"
                                            type="location"
                                            className={classnames("form-control", {
                                                invalid: errors.location
                                            })}
                                        />
                                        <p className="text-danger float-right">{errors.location}</p>
                                    </div>

                                    <div className="form-group col-md-6" style={{ paddingBottom: '15px' }}>
                                        <label style={{ color: "white" }} htmlFor="ticketsLink">Tickets Link</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.ticketsLink}
                                            placeholder="Tickets Link"
                                            error={errors.ticketsLink}
                                            id="ticketsLink"
                                            type="ticketsLink"
                                            className={classnames("form-control", {
                                                invalid: errors.ticketsLink
                                            })}
                                        />
                                        <p className="text-danger float-right">{errors.ticketsLink}</p>
                                    </div>

                                </div>

                                <div className="form-row">

                                    <div className="form-group col-md-6" style={{ paddingBottom: '15px' }}>
                                        <label style={{ color: "white" }} htmlFor="startDate">Start Date & Time</label>
                                        <br />
                                        <DatePicker
                                            // customInput={<ExampleCustomInput />}
                                            selected={this.state.startDate}
                                            onChange={value => this.setState({ startDate: value })}
                                            showTimeSelect
                                            timeFormat="p"
                                            timeIntervals={15}
                                            timeCaption="time"
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            locale="en"
                                        />
                                    </div>

                                    <div className="form-group col-md-6" style={{ paddingBottom: '15px' }}>
                                        <label style={{ color: "white" }} htmlFor="timeText">Time Text</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.timeText}
                                            placeholder="Time Text"
                                            error={errors.timeText}
                                            id="timeText"
                                            type="timeText"
                                            className={classnames("form-control", {
                                                invalid: errors.timeText
                                            })}
                                        />
                                        <p className="text-danger float-right">{errors.timeText}</p>
                                    </div>

                                </div>

                                <div className="form-group">
                                    <label style={{ color: "white" }} htmlFor="description">Description</label>
                                    <textarea
                                        onChange={this.onChange}
                                        placeholder="Description"
                                        value={this.state.description}
                                        error={errors.description}
                                        id="description"
                                        type="description"
                                        className={classnames("form-control", {
                                            invalid: errors.description
                                        })}
                                        style={{ height: '30vh' }}
                                    />

                                    <p className="text-danger float-right">{errors.description}</p>
                                </div>

                            </AvForm>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12" style={{ paddingTop: '15px' }}>
                            <Button /*type="submit"*/ form="addEventForm" color="primary" /*outline*/ className="float-right">
                                Add
                            </Button>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

const mapSizesToProps = ({ width }) => ({
    collapseButtonIsShowing: width < 1200,
    width: width
})

//   export default (withSizes(mapSizesToProps)(PhotosPage))

export default connect(mapStateToProps, { addEvent })(withRouter(withSizes(mapSizesToProps)(AddEvent)));
