import React, { Component } from "react";
import Footer from '../layout/Footer';

import NavigationBarMobile from '../layout/NavigationBarMobile'
import NavigationBarDesktop from '../layout/NavigationBarDesktop'

import Helmet from 'react-helmet';
import withSizes from 'react-sizes'
import MediaBox from './MediaBox';

var videoDisplayRatio = true; 

const screenRatio = (window.innerWidth / window.innerHeight);

class VideoPage extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = {
            navbarIsOpen: false
        }
    }

    toggle() {
        this.setState({
            navbarIsOpen: !this.state.navbarIsOpen
        })
    }

    render() {
        return (

            <div>
                <Helmet>
                    <title>{"Jake Whitesell – Video"}</title>
                    <body className={"normal"} />
                </Helmet>
                {window.innerWidth < 1050 ?
                    <NavigationBarMobile />
                    :
                    <NavigationBarDesktop />
                }

                <div className="row">
                    <div className="col-lg-12">
                        <p className="customHeader" style={{ textAlign: 'center' }}>
                            Video
                        </p>
                    </div>
                </div>

                <div style={{ height: "25px" }}>
                <font style={{fontSize: "0px"}}>
                {videoDisplayRatio = (window.innerWidth < window.innerHeight) ? "75%" : 
                ( (window.innerWidth < 900) ?
                "50%" /* ((((window.innerHeight - 60) / (window.innerWidth - 20)) * 100) + "%") */ 
                : "75%")}
                </font>
                
                </div>
        
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8" style={{ textAlign: "center" }}>

                        <div className="row">

                            <div className="col-xl-2"></div>

                            <div className="col-xl-8">

                            <div width="100" height="0"></div>

                                <div>
                                    <p className="customTextSmall1" style={{ marginBottom: "5px", lineHeight: "1.3", textAlign: "left" }}>
                                        Soul Porpoise, January 2022
                                        <br />
                                        at Future Fields (Burlington, VT)
                                        <br />
                                        "Oh Come Back, Baby" <font style={{fontSize: "15px"}}>(by Ira Friedman)</font>
                                    </p>
                                    <div style={{position : "relative", paddingTop : videoDisplayRatio}}>
                                        <iframe className="videoWrapperIframe" src="https://www.youtube.com/embed/ZOx3O0I4678" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                </div>

                                <div style={{ height: "55px" }}></div>
                                
                                <div>
                                    <p className="customTextSmall1" style={{ marginBottom: "5px", lineHeight: "1.3", textAlign: "left" }}>
                                        Kat Wright Band, April 2014
                                        <br />
                                        at Signal Kitchen (Burlington, VT)
                                        <br />
                                        "Those Days"
                                    </p>
                                    <div style={{position : "relative", paddingTop : videoDisplayRatio}}>
                                        <iframe className="videoWrapperIframe" src="https://www.youtube.com/embed/Rfr-tZm5qWc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                </div>

                                <div style={{ height: "55px" }}></div>

                                <div> 
                                    <p className="customTextSmall1" style={{ marginBottom: "5px", lineHeight: "1.3", textAlign: "left" }}>
                                        Kat Wright Band, March 2018
                                        <br />
                                        at Higher Ground (Burlington, VT)
                                        <br />
                                        Full Concert recorded for "Bardo" TV show
                                    </p>
                                    <div style={{position : "relative", paddingTop : videoDisplayRatio}}>
                                        <iframe className="videoWrapperIframe" src="https://www.youtube.com/embed/FyWBuGELn2w" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                </div>

                                <div style={{ height: "55px" }}></div>

                                <div>
                                    <p className="customTextSmall1" style={{ marginBottom: "5px", lineHeight: "1.3", textAlign: "left" }}>
                                        Jake Whitesell's Cannonball Quintet, November 2022
                                        <br />
                                        at The 126 (Burlington, VT)
                                        <br />
                                        "Introduction to a Samba" <font style={{fontSize: "15px"}}>(by Julian Cannonball Adderley)</font>
                                    </p>
                                    <div style={{position : "relative", paddingTop : videoDisplayRatio}}>
                                        <iframe className="videoWrapperIframe" src="https://www.youtube.com/embed/lYKZYdQ6HDA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                </div>

                                <div style={{ height: "55px" }}></div>
                                
                                {/* <div>
                                <div>
                                    <p className="customTextSmall1" style={{ marginBottom: "5px", lineHeight: "1.3", textAlign: "left" }}>
                                        Jake Whitesell's Cannonball Quintet, April 2022
                                        <br />
                                        at The 126 (Burlington, VT)
                                        <br />
                                        "The Chant" <font style={{fontSize: "15px"}}>(by Victor Feldman)</font>
                                    </p>
                                    <div style={{position : "relative", paddingTop : videoDisplayRatio}}>
                                        <iframe className="videoWrapperIframe" src="https://www.youtube.com/embed/_SVMefiLOso" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                </div>

                                <div style={{ height: "55px" }}></div>

                                </div> */}

                                

                                {/* <div>
                                    <p className="customTextSmall1" style={{ marginBottom: "5px", lineHeight: "1.3", textAlign: "left" }}>
                                        Whitesell Brothers Quintet, June 2019
                                        <br />
                                        at Leunigs (Burlington, VT)
                                        <br />
                                        "Someday My Prince Will Come" <font style={{fontSize: "15px"}}>(by Frank Churchill)</font>
                                    </p>
                                    <div style={{position : "relative", paddingTop : videoDisplayRatio}}>
                                        <iframe className="videoWrapperIframe" src="https://www.youtube.com/embed/7buKu8ELfwQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                </div>

                                <div style={{ height: "55px" }}></div> */}

                                <div>

                                    <p className="customTextSmall1" style={{ marginBottom: "5px", lineHeight: "1.3", textAlign: "left" }}>
                                        Jake Whitesell/Andrew Moroz Sextet, {(window.innerWidth < 400) ? <br/> : "" }May 2014
                                        <br />
                                        at The Burlington Discover Jazz Festival {(window.innerWidth < 450) ? <br/> : "" }offical kick-off party
                                        <br/>
                                        "This Is For Albert" <font style={{fontSize: "15px"}}>(by Wayne Shorter)</font>
                                    </p>

                                    <div style={{position : "relative", paddingTop : videoDisplayRatio}}>
                                        <iframe className="videoWrapperIframe" src="https://www.youtube.com/embed/NAhkR9jbPn0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                </div>

                                <div style={{ height: "55px" }}></div>

                                <div>
                                    <p className="customTextSmall1" style={{ marginBottom: "5px", lineHeight: "1.3", textAlign: "left" }}>
                                        Jake Whitesell/Andrew Moroz Sextet, {(window.innerWidth < 400) ? <br/> : "" }May 2014
                                        <br />
                                        at The Burlington Discover Jazz Festival {(window.innerWidth < 450) ? <br/> : "" }offical kick-off party
                                        <br/>
                                        "Armed and Hammered" <font style={{fontSize: "15px"}}>(by Robinson Morse)</font>
                                    </p>

                                    <div style={{position : "relative", paddingTop : videoDisplayRatio}}>
                                        <iframe className="videoWrapperIframe"  src="https://www.youtube.com/embed/xFkTb4vfpdA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                </div>

                                <div style={{ height: "55px" }}></div>
                                
                                {/*}
                                <div>

                                    <p className="customText" style={{ marginBottom: "0px", lineHeight: "1.3", textAlign: "left" }}>
                                        A Fly Allusion, March 2013
                                        <br />
                                        Positive Pie 2, Montpelier, VT
                                        <br />
                                        "Will It Go Around In Circles" <font style={{fontSize: "15px"}}>(by Billy Preston)</font>
                                    </p>

                                    <div style={{position : "relative", paddingTop : videoDisplayRatio}}>
                                        <iframe className="videoWrapperIframe"  src="https://www.youtube.com/embed/evn806Z-KqU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                </div>
                                                                
                                <div style={{ height: "55px" }}></div>
                                */}

                                <div>

                                    <p className="customTextSmall1" style={{ marginBottom: "0px", lineHeight: "1.3", textAlign: "left" }}>
                                        A Fly Allusion, March 2013
                                        <br />
                                        at Positive Pie (Montpelier, VT)
                                        <br />
                                        "You Can't Hold Me Down" <font style={{fontSize: "15px"}}>(by US3)</font>
                                    </p>

                                    <div style={{position : "relative", paddingTop : videoDisplayRatio}}>
                                        <iframe className="videoWrapperIframe"  src="https://www.youtube.com/embed/IFUgzcqbfLc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                </div>

                                <div style={{ height: "55px" }}></div>
                                {/*}
                                <div>

                                    <p className="customTextSmall1" style={{ marginBottom: "5px", lineHeight: "1.3", textAlign: "left" }}>
                                        Jake Whitesell Quartet, April 2005
                                        <br />
                                        Snell Theater, Crane School of Music, {(window.innerWidth < 450) ? <br/> : "" }SUNY Potsdam
                                        <br />
                                        "Soaring" <font style={{fontSize: "15px"}}>(by Jake Whitesell)</font>
                                    </p>

                                    <div style={{position : "relative", paddingTop : videoDisplayRatio}}>
                                        <iframe className="videoWrapperIframe"  src="https://www.youtube.com/embed/JYRtMKT_m9E" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                </div>*/}


                            </div>

                            <div className="col-xl-2"></div>
                        </div>
                    </div>
                    <div className="col-md-2"></div>
                </div>

{/* 
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8" style={{ height: "256px" }}></div>
                    <div className="col-md-2"></div>
                </div>
*/}


                <Footer />
            </div>
        );
    }
}

const mapSizesToProps = ({ width }) => ({
    collapseButtonIsShowing: width < 1200,
    width: width
})

export default (withSizes(mapSizesToProps)(VideoPage))
