
import React, { Component/*, useRef*/ } from "react";
import { /*Link,*/ withRouter } from "react-router-dom";
import { Button } from 'reactstrap';
import Helmet from 'react-helmet';
import withSizes from 'react-sizes'
// import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addUser } from "../../actions/adminActions";
import classnames from "classnames";
import Footer from "../layout/Footer";
import NavigationBarMobile from '../layout/NavigationBarMobile'
import NavigationBarDesktop from '../layout/NavigationBarDesktop'

class AddUser extends Component {

    constructor(props) {
        super(props);

        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            password2: "",
            errors: {},

            firstNameComparator: "",
            lastNameComparator: "",
            emailComparator: "",
            passwordComparator: "",
            password2Comparator: ""

        };

        this.userComparator = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            password: this.state.password,
            password2: this.state.password2
        }
        console.log('at end of EditUser.constructor; values:');
        console.log('firstName: ' + this.state.firstName);
        console.log('lastName: ' + this.state.lastName);
        console.log('email: ' + this.state.email);
        console.log('password: ' + this.state.password);
        console.log('id: ' + this.state.id);
    }

    // componentDidMount() {
    //     // If logged in and user navigates to Register page, should redirect them to dashboard
    //     if (this.props.auth.isAuthenticated) {
    //         this.props.history.push("/dashboard");
    //     }

    //     console.log("componentDidMount called.")
    // }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSubmit = e => {
        e.preventDefault();

        const errors = this.state.errors;

        console.log("errors: " + errors);

        const newUser = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            roles: ['user'],
            password: this.state.password,
            password2: this.state.password2,
        };

        this.props.addUser(newUser, this.props.history);
    };

    fieldsHaveChanged() {
        if ((this.state.firstName !== this.userComparator.firstName) || (this.state.lastName !== this.userComparator.lastName) || (this.state.email !== this.userComparator.email) || (this.state.password !== this.userComparator.password) || (this.state.password2 !== this.userComparator.password2)) {
            return true;
        } else {
            return false;
        }
    }

    confirmDiscard() {
        console.log('running AddUser.confirmDiscard().');
        window.confirm("Are you sure you want to go back? Any unsaved changes will be lost.") && this.props.history.goBack()
    }

    render() {

        const { errors } = this.state;

        console.log('Running AddUser.render().');

        return (
            <div>
                <Helmet bodyAttributes={{ style: "background-color: #000000" }}>
                    <title>{"Jake Whitesell – Add User"}</title>
                </Helmet>
                {this.props.collapseButtonIsShowing ?
                    <NavigationBarMobile />
                    :
                    <NavigationBarDesktop />
                }

                <div className="container">

                    <div style={{height: "128px"}}></div>

                    <div className="row">
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4">
                            <Button color="primary" /*outline*/ onClick={e => this.fieldsHaveChanged() ? this.confirmDiscard() : this.props.history.goBack()}>
                                Back
                            </Button>
                        </div>
                        <div className="col-lg-4"></div>
                    </div>

                    <div className="row">

                        <div className="col-lg-4"></div>
                        <div className="col-lg-4">
                            {/* <Button color="primary" outline onClick={e => this.fieldsHaveChanged() ? this.confirmDiscard() : this.props.history.goBack()}>
                            Back
                        </Button> */}

                            <div className="row">
                                <div className="col-lg-12">
                                    <p className="customHeader" style={{ textAlign: 'center' }}>
                                        Add User
                                    </p>
                                </div>
                            </div>

                            <form noValidate onSubmit={this.onSubmit} id="addUserForm">

                                <div className="form-group" style={{ paddingBottom: '15px' }}>
                                    <label style={{ color: "white" }} htmlFor="firstName">First Name</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.firstName}
                                        error={errors.firstName}
                                        id="firstName"
                                        type="text"
                                        className={classnames("form-control", {
                                            invalid: errors.firstName
                                        })}
                                    />
                                    <p className="text-danger float-right">{errors.firstName}</p>

                                    {/* <span><br /><p className="text-danger">{errors.firstName}</p></span> */}

                                </div>

                                <div className="form-group" style={{ paddingBottom: '15px' }}>
                                    <label style={{ color: "white" }} htmlFor="lastName">Last Name</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.lastName}
                                        error={errors.lastName}
                                        id="lastName"
                                        type="text"
                                        className={classnames("form-control", {
                                            invalid: errors.lastName
                                        })}
                                    />
                                    <p className="text-danger float-right">{errors.lastName}</p>
                                    {/* <span className="red-text"><br />{errors.lastName}</span> */}
                                </div>

                                <div className="form-group" style={{ paddingBottom: '15px' }}>
                                    <label style={{ color: "white" }} htmlFor="email">Email</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.email}
                                        error={errors.email}
                                        id="email"
                                        type="email"
                                        className={classnames("form-control", {
                                            invalid: errors.email
                                        })}
                                    />
                                    <p className="text-danger float-right">{errors.email}</p>
                                    {/* <span className="red-text"><br />{errors.email}</span> */}
                                </div>

                                <div className="form-group" style={{ paddingBottom: '15px' }}>
                                    <label style={{ color: "white" }} htmlFor="password">Password</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.password}
                                        error={errors.password}
                                        id="password"
                                        type="password"
                                        className={classnames("form-control", {
                                            invalid: errors.password
                                        })}
                                    />
                                    <p className="text-danger float-right">{errors.password}</p>
                                    {/* <span className="red-text"><br />{errors.password}</span> */}
                                </div>

                                <div className="form-group" style={{ paddingBottom: '15px' }}>
                                    <label style={{ color: "white" }} htmlFor="password2">Confirm Password</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.password2}
                                        error={errors.password2}
                                        id="password2"
                                        type="password"
                                        className={classnames("form-control", {
                                            invalid: errors.password2
                                        })}
                                    />
                                    <p className="text-danger float-right">{errors.password2}</p>
                                    {/* <span className="red-text"><br />{errors.password2}</span> */}
                                </div>

                                {/* <Button type="submit" color="primary" outline className="float-right">
                                Add
                            </Button> */}

                            </form>
                        </div>
                        <div className="col-lg-4"></div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4">
                            <Button type="submit" form="addUserForm" color="primary" /*outline*/ className="float-right">
                                Add
                            </Button>
                        </div>
                        <div className="col-lg-4"></div>
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

const mapSizesToProps = ({ width }) => ({
    collapseButtonIsShowing: width < 1200,
    width: width
})

export default connect(mapStateToProps, { addUser })(withRouter(withSizes(mapSizesToProps)(AddUser)));