import React, { Component } from "react";
// import { Link } from "react-router-dom";

class Footer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let photoCredit = this.props.photoCredit;

        return (
            // <body class="d-flex flex-column min-vh-100">

            <div className="d-flex flex-column">

                <div className="wrapper flex-grow-1"></div>
                <footer>
                    <div className="container-fluid text-center py-4 bg-black text-white-50">
                        <small className="footerText">
                            {photoCredit !== undefined ? <div>Photo credit: {photoCredit}</div> : <div></div>}
                        Copyright &copy; 2020 Jake Whitesell
                        </small>
                    </div>
                </footer>

            </div>

        );
    }
}

export default Footer;
