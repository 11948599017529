import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import Helmet from 'react-helmet'

import { setCurrentUser, logoutUser } from "./actions/authActions";
import { Provider } from "react-redux";
// import store from "./store";
// import {store, persistor } from './store';
import returnStoreAndPersistor from "./store";

// import persistor from "./store";

import ScrollToTop from './ScrollToTop'
// import { connect } from 'react-redux';

// import Landing from "./components/layout/Landing";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import PrivateRoute from "./components/private-route/PrivateRoute";
import AdminRoute from "./components/admin-route/AdminRouteContainer";
import Dashboard from "./components/dashboard/Dashboard";

import HomePage from "./components/home/HomePage";
import AboutPage from './components/pages/AboutPage'
import CalendarPage from './components/pages/CalendarPage'
import ListenPage from './components/pages/ListenPage'
import VideoPage from './components/pages/VideoPage'
import PhotosPage from './components/pages/PhotosPage'
import LessonsPage from './components/pages/LessonsPage'
import ContactPage from './components/pages/ContactPage'

import 'bootstrap/dist/css/bootstrap.css';

import './css/main.scss';

import "./App.css";

import AdminPanel from "./components/admin-panel/AdminPanel";

import ViewUsers from "./components/admin-panel/ViewUsers";
import AddUser from "./components/admin-panel/AddUser";
import EditUser from "./components/admin-panel/EditUser";

import ViewGroups from "./components/admin-panel/ViewGroups";
import AddGroup from "./components/admin-panel/AddGroup";
import EditGroup from "./components/admin-panel/EditGroup";

import ViewEvents from "./components/admin-panel/ViewEvents"
import AddEvent from "./components/admin-panel/AddEvent"
import EditEvent from "./components/admin-panel/EditEvent"

import GroupDetail from "./components/pages/GroupDetail";
import WBQ from "./components/pages/bands/WBQ";

// import { Spinner } from "reactstrap";

import { PersistGate } from 'redux-persist/integration/react'
import CannonballQuintet from "./components/pages/bands/CannonballQuintet";

const { store } = returnStoreAndPersistor()
const { persistor } = returnStoreAndPersistor()

console.log('running App.js check for JWT...');

// Check for token to keep user logged in
if (localStorage.jwtToken) {

  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);

  // Decode token and get user info and exp
  const decoded = jwt_decode(token);

  console.log('DEBUG: value of JSON.stringify(store.auth): ' + JSON.stringify(store.auth));
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds

  if (decoded.exp < currentTime) {

    // Logout user
    store.dispatch(logoutUser());

    // Redirect to login
    window.location.href = "./login";

  }
}

class App extends Component {

  componentWillMount() {
    console.log('App.componentWillMount called.');

    global.serverAddress = (process.env.NODE_ENV === 'production') ? 'https://damp-dusk-51685.herokuapp.com' : 'http://localhost:5000'
  }

  render() {
    console.log('running App.render()');

    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <ScrollToTop>
              <div className="App" style={{ zIndex: 0 }}>
                <div className="container-fluid" style={{ zIndex: 0 }}>
                  {/* <Helmet bodyAttributes={{ style: "background-color: #000000" }} /> */}
                  {/* <NavigationBar /> */}
                  <Route exact path="/" component={HomePage} />
                  <Route exact path="/about" component={AboutPage} />
                  <Route exact path="/shows" component={CalendarPage} />
                  <Route exact path="/listen" component={ListenPage} />
                  <Route exact path="/Video" component={VideoPage} />
                  <Route exact path="/photos" component={PhotosPage} />
                  <Route exact path="/lessons" component={LessonsPage} />
                  <Route exact path="/contact" component={ContactPage} />

                  <Route exact path="/register" component={Register} />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/forgotpassword" component={ForgotPassword} />
                  <Route exact path="/resetpassword/:id/:token" component={ResetPassword} />
                  <Route path="/groups/:groupId" component={GroupDetail} />
                  <Route path="/whitesell-brothers-quartet" component={WBQ} />
                  <Route path="/cannonball-quintet" component={CannonballQuintet} />
                  <Switch>
                    <PrivateRoute exact path="/dashboard" component={Dashboard} />
                  </Switch>
                  <Switch>
                    <AdminRoute exact path="/admin-panel" component={AdminPanel} />
                    <AdminRoute exact path="/admin-panel/view-users" component={ViewUsers} />
                    <AdminRoute exact path="/admin-panel/add-user" component={AddUser} />
                    <AdminRoute exact path="/admin-panel/edit-user" component={EditUser} />
                    <AdminRoute exact path="/admin-panel/view-groups" component={ViewGroups} />
                    <AdminRoute exact path="/admin-panel/add-group" component={AddGroup} />
                    <AdminRoute exact path="/admin-panel/edit-group" component={EditGroup} />
                    <AdminRoute exact path="/admin-panel/view-events" component={ViewEvents} />
                    <AdminRoute exact path="/admin-panel/add-event" component={AddEvent} />
                    <AdminRoute exact path="/admin-panel/edit-event" component={EditEvent} />
                  </Switch>
                </div>
              </div>
            </ScrollToTop>
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
