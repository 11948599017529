import React, { Component } from "react";
import PropTypes from "prop-types";
import Helmet from 'react-helmet';
import withSizes from 'react-sizes'
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";

import NavigationBarMobile from '../layout/NavigationBarMobile'
import NavigationBarDesktop from '../layout/NavigationBarDesktop'

class Dashboard extends Component {
  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };

  render() {
    const { user } = this.props.auth;

    console.log('value of user.roles: ' + user.roles);

    return (

      <div>
        <Helmet bodyAttributes={{ style: "background-color: #000000" }} />
        {this.props.collapseButtonIsShowing ?
          <NavigationBarMobile />
          :
          <NavigationBarDesktop />
        }

        <div className="container">

          <div style={{ height: "128px" }}></div>

          <div className="row">
            <div className="col-lg-12">
              <p className="customHeader" style={{ textAlign: 'center' }}>
                Dashboard
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 customText" style={{ color: "white" }}>
              <b>First Name:</b> {user.firstName}
              <br />
              <b>Last Name:</b> {user.lastName}
              <br />
              <b>Email:</b> {user.email}
              <br />
              <b>Roles:</b> {user.roles.join(", ")}
              <br />
              <b>ID:</b> {user.id}
            </div>
          </div>

        </div>

      </div>

    );
  }
}

Dashboard.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

const mapSizesToProps = ({ width }) => ({
  collapseButtonIsShowing: width < 1200,
  width: width
})

export default connect(mapStateToProps, { logoutUser })(withSizes(mapSizesToProps)(Dashboard));
