import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import withSizes from 'react-sizes'
import axios from 'axios';
import GroupDataTable from './GroupDataTable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { decrementProgress, incrementProgress } from '../../actions/progress';
import { deleteGroup } from "../../actions/adminActions";
import Footer from "../layout/Footer";

import NavigationBarMobile from '../layout/NavigationBarMobile'
import NavigationBarDesktop from '../layout/NavigationBarDesktop'

import { Nav } from 'reactstrap';

class ViewGroups extends Component {

    constructor(props) {
        super(props);

        console.log('\n\nViewGroups.constructor called.');
        console.log('value of this.props.auth.user:');
        console.log(this.props.auth.user);

        // bound functions
        this.deleteGroupFunction = this.deleteGroupFunction.bind(this);
        this.editGroupFunction = this.editGroupFunction.bind(this);

        this.state = { groupsCollection: [] };

    }

    componentDidMount() {
        console.log('ViewGroups.componentDidMount called.')

        // axios.get('http://localhost:5000/api/courses')
        const url = global.serverAddress + '/api/groups'

        axios.get(url)
            .then(res => {
                this.setState({ groupsCollection: res.data });
            })
            .catch(function (error) {
                console.log(error);
            })
    }


    editGroupFunction(id) {
        console.log('running ViewGroups.editGroupFunction; value of id passed: ' + id);
        console.log('running ViewGroups.editGroupFunction; value of this.props.history: ' + this.props.history);


        const url = global.serverAddress + '/api/groups/edit/' + id;

        // const url = 'http://localhost:5000/api/courses/edit/' + id


        axios.get(url)
            // axios.get('/api/users/edit')            
            .then(res => {
                console.log('reached second then block; value of res: ');
                console.log(res);
                if (res.data.images === undefined) {
                        res.data.images = {}
                }
                this.props.history.push({
                    pathname: '/admin-panel/edit-group',
                    search: '?query=abc',
                    state: { group: res.data }
                })
            })
            .catch(function (error) {
                console.log('reached catch block.');
                console.log(error);
            })
    }

    dataTable(mode) {
        return this.state.groupsCollection.map((data, i) => {
            console.log('creating data table for user number ' + i)
            return <GroupDataTable obj={data} key={i} mode={mode} deleteGroupFunction={this.deleteGroupFunction} editGroupFunction={this.editGroupFunction} />;
        });
    }

    deleteGroupFunction(id) {

        console.log('ViewGroups.deleteGroupFunction called.');
        console.log('value of this.props.deleteGroup: ' + this.props.deleteGroup);
        console.log('value of id: ' + id);
        this.props.deleteGroup(id, this.updateState);

    }


    updateState = (newState) => {
        console.log('running ViewGroups.updateState.');

        // this.setState({ usersCollection: newState });
        this.componentDidMount();
    }

    render() {
        console.log('running ViewGroups.render; value of this.props.history: ' + this.props.history);
        return (
            <div className="wrapper-users">
                <Helmet bodyAttributes={{ style: "background-color: #000000" }}>
                    <title>{"Jake Whitesell – View Groups"}</title>
                </Helmet>
                {this.props.collapseButtonIsShowing ?
                    <NavigationBarMobile />
                    :
                    <NavigationBarDesktop />
                }
                <div className="container-fluid">

                    <div style={{ height: "128px" }}></div>

                    <div className="row">
                        <div className="col-lg-12">
                            <p className="customHeader" style={{ textAlign: 'center' }}>
                                Groups
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="float-left">
                                <Link to="/admin-panel">
                                    Back
                                </Link>
                            </div>

                            <div className="float-right">
                                <Link to="/admin-panel/add-group">
                                    Add Group
                            </Link>
                            </div>
                        </div>
                    </div>

                    <div style={{ height: "32px" }}></div>


                    <table className="table table-striped">
                        <thead className="thead-dark">
                            <tr style={{ color: "white" }}>
                                <td>ID</td>
                                <td>Name</td>
                                <td>Description</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {this.dataTable(0)}
                        </tbody>
                    </table>
                </div>
                <Footer />
            </div>
        )
    }
}

ViewGroups.propTypes = {
    deleteGroup: PropTypes.func.isRequired,
    editGroup: PropTypes.func.isRequired,
    incrementProgress: PropTypes.func.isRequired,
    decrementProgress: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth
});

const mapSizesToProps = ({ width }) => ({
    collapseButtonIsShowing: width < 1200,
    width: width
})

export default connect(mapStateToProps, { deleteGroup, incrementProgress, decrementProgress, withRouter })(withSizes(mapSizesToProps)(ViewGroups));
