
import React, { Component/*, useRef*/ } from "react";
import { /*Link,*/ withRouter } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Helmet from 'react-helmet';
import withSizes from 'react-sizes'
// import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateUser } from "../../actions/adminActions";
import { updateCurrentUser } from "../../actions/authActions";
// import classnames from "classnames";
import Footer from "../layout/Footer";

import NavigationBarMobile from '../layout/NavigationBarMobile'
import NavigationBarDesktop from '../layout/NavigationBarDesktop'

import GroupDataTable from "./GroupDataTable";
// import { registerUserForCourse } from "../../actions/groupActions";
// import { unregisterUserForCourse } from "../../actions/groupActions";
import axios from "axios";
import arraysAreEqual from '../../utils/helperMethods';

// NOTE: here I have copied code over from EditCourse, adjusted it to suit this use (but haven't finished that yet), and added CourseDataTable
// currently the table isn't showing any of a user's courses; not sure whether this is because we aren't fetching them with the user from the server, or some other factor
// so, leaving off here with things in a sort of half-baked state
// future self: check out render method to see what I have (and haven't yet) done there to adapt the functionality now in EditCourse to this component
// also look at the flow of data and determine whether we need to change things a bit (e.g., by adding a coursesCollection or something)

class EditUser extends Component {

    constructor(props) {
        super(props);
        const user = this.props.location.state.user;

        this.state = {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            // password: user.password,
            roles: user.roles,
            id: user._id,
            // password2: "",
            // errors: {}

            firstNameComparator: user.firstName,
            lastNameComparator: user.lastName,
            emailComparator: user.email,

            didFetchCourses: false,
            coursesCollection: [],
            popupIsOpen: false

        };

        // this.unregisterUserFunction = this.unregisterUserFunction.bind(this);
        // this.registerUserFunction = this.registerUserFunction.bind(this);
        this.toggle = this.toggle.bind(this);

        this.userComparator = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
        }

        // console.log('at end of EditUser.constructor; values:');
        // console.log('firstName: ' + this.state.firstName);
        // console.log('lastName: ' + this.state.lastName);
        // console.log('email: ' + this.state.email);
        // console.log('password: ' + this.state.password);
        // console.log('id: ' + this.state.id);
    }

    // dataTable(collection, mode) {
    //     return collection.map((data, i) => {
    //         console.log('creating data table for course number ' + i);
    //         return <CourseDataTable obj={data} key={i} mode={mode} registerUserFunction={this.registerUserFunction} unregisterUserFunction={this.unregisterUserFunction} />;
    //     });
    // }

    // registerUserFunction(course) {
    //     console.log('running EditUser.registerUserFunction.');
    //     console.log('current value of this.state.courses: ' + JSON.stringify(this.state.courses));
    //     // if the user object's courses array does not already contain the course to be added...
    //     if (!this.state.courses.includes(course)) {
    //         console.log('this.state.courses does NOT contain course with id ' + course._id);

    //         // create a copy of this.state.courses
    //         var coursesCopy = this.state.courses.concat();

    //         // add the course to this copy
    //         coursesCopy.push(course);

    //         // create a copy of this.state.coursesCollection
    //         var newCoursesCollection = this.state.coursesCollection.concat();

    //         // get the index of the course we just added
    //         var index = this.state.coursesCollection.map(course => { return course._id }).indexOf(course);

    //         // remove it from the array copy
    //         newCoursesCollection.splice(index, 1);

    //         // assign this.state.courses and this.state.coursesCollction to equal the new arrays using setState
    //         this.setState({
    //             courses: coursesCopy,
    //             coursesCollection: newCoursesCollection
    //         })

    //     } else {
    //         console.log('this.state.courses DOES contain course with id ' + course._id);
    //     }
    //     console.log('finished EditUser.registerUserFunction; ending value of this.state.courses: ' + JSON.stringify(this.state.courses));
    // }

    // unregisterUserFunction(course) {
    //     // if the user object's courses array does already contain the course to be added...
    //     if (this.state.courses.includes(course)) {

    //         if (this.state.courses.includes(course)) {
    //             const index = this.state.courses.indexOf(course);
    //             if (index > -1) {
    //                 const newCourses = this.state.courses.concat();
    //                 newCourses.splice(index, 1);

    //                 this.setState({
    //                     courses: newCourses
    //                 })

    //                 // (COPIED FROM EditCourse)
    //                 // this.setState(prevState => ({
    //                 //     course: {
    //                 //         ...prevState.course,
    //                 //         registrants: newRegistrants
    //                 //     }
    //                 // }))
    //             }
    //         }


    //         // OLD (not working)
    //         // // create a copy of this.state.courses
    //         // var coursesCopy = this.state.courses.concat();

    //         // // remove the course from this copy
    //         // coursesCopy.pull(course);

    //         // // assign the new array to this.state.courses using setState
    //         // this.setState({
    //         //     courses: coursesCopy
    //         // })
    //     }
    // }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };


    onSubmit = e => {
        console.log('running EditUser.onSubmit.');
        e.preventDefault();

        const errors = this.state.errors;

        console.log("errors: " + errors);

        const newUser = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            // courses: this.state.courses.map(course => { return course._id }),
            roles: this.state.roles,
            // password: this.state.password,
            id: this.state.id
            // password2: this.state.password2
        };

        console.log('created newUser; value:');
        console.log(newUser);


        // this.props.updateUser(newUser, this.props.history);
        this.props.updateUser(this.userComparator, newUser, this.props.history);

        // NEWER

        // if the user being edited is the user currently signed in...
        if (newUser.id === this.props.auth.user.id) {
            console.log('newUser.id === this.props.auth.user.id; calling updateCurrentUser.');
            // update current user with changes
            const currentUser = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                roles: this.state.roles,
                // password: this.state.password,
                id: this.state.id
                // password2: this.state.password2
            };
            this.props.updateCurrentUser(currentUser);
        } else {
            console.log('newUser.id !== this.props.auth.user.id; NOT calling updateCurrentUser.');
        }

        // // NEW        

        // // If current user was among those users unregistered from the course...

        // const currentUserWasUnregistered = ((this.courseComparator.registrants.map(registrant => { return registrant._id }).includes(this.props.auth.user.id)) && !(this.state.course.registrants.map(registrant => { return registrant._id }).includes(this.props.auth.user.id)));

        // const currentUserWasRegistered = (!(this.courseComparator.registrants.map(registrant => { return registrant._id }).includes(this.props.auth.user.id)) && (this.state.course.registrants.map(registrant => { return registrant._id }).includes(this.props.auth.user.id)))

        // // if ((this.courseComparator.registrants.map(registrant => { return registrant._id }).includes(this.props.auth.user.id)) && !(this.state.course.registrants.map(registrant => { return registrant._id }).includes(this.props.auth.user.id))) {

        // if (currentUserWasUnregistered) {

        //     console.log('current user WAS among those users unregistered for the course.');

        //     // then we update the current user's 'courses' array

        //     // create a copy of user's existing 'courses' array
        //     var newCourses = this.props.auth.user.courses.concat();

        //     // if this array contains the current course's id...
        //     if (newCourses.map(course => { return course._id }).includes(this.state.course.id)) {

        //         console.log('newCourses DOES contain current course id.');

        //         console.log('removing current course from array newCourses.');
        //         const index = newCourses.map(course => { return course._id }).indexOf(this.state.course.id);
        //         newCourses.splice(index, 1);

        //         console.log('creating new user object.');
        //         const newUser = {
        //             ...this.props.auth.user,
        //             courses: newCourses
        //         }

        //         console.log('value of newUser:');
        //         console.log(newUser);

        //         console.log('calling this.props.updateUser.');

        //         this.props.updateUser(newUser, null);

        //         console.log('calling this.props.updateCurrentUser.')
        //         this.props.updateCurrentUser(newUser);

        //         console.log('new value of this.props.auth.user:');
        //         console.log(this.props.auth.user);

        //     } else { console.log('newCourses DOES NOT contain current course id; value: ' + JSON.stringify(newCourses)); }

        // } else { console.log('current user WAS NOT among those users unregistered from the course.'); }

        // if (currentUserWasRegistered) {

        //     console.log('current user WAS among those users registered for the course.');

        //     // then we update the current user's 'courses' array

        //     // create a copy of user's existing 'courses' array
        //     var newCourses = this.props.auth.user.courses.concat();

        //     // if this array does not contain the current course's id...
        //     if (!(newCourses.map(course => { return course._id }).includes(this.state.course.id))) {

        //         console.log('newCourses DOES NOT contain current course id.');
        //         console.log('adding current course to array newCourses.');

        //         const course = {
        //             title: this.state.course.title,
        //             subtitle: this.state.course.subtitle,
        //             description: this.state.course.description,
        //             registrants: this.state.course.registrants,
        //             maxRegistrants: this.state.course.maxRegistrants,
        //             _id: this.state.course.id,
        //         }

        //         newCourses.push(course);

        //         console.log('creating new user object.');
        //         const newUser = {
        //             ...this.props.auth.user,
        //             courses: newCourses
        //         }

        //         console.log('value of newUser:');
        //         console.log(newUser);

        //         console.log('calling this.props.updateUser.');

        //         this.props.updateUser(newUser, null);

        //         console.log('calling this.props.updateCurrentUser.')
        //         this.props.updateCurrentUser(newUser);

        //         console.log('new value of this.props.auth.user:');
        //         console.log(this.props.auth.user);

        //     } else { console.log('newCourses DOES NOT contain current course id; value: ' + JSON.stringify(newCourses)); }

        // } else { console.log('current user WAS NOT among those users registered from the course.'); }

        // // END NEW
    };

    fieldsHaveChanged() {
        console.log('EditUser.fieldsHaveChanged called.');
        // console.log('value of ')
        if ((this.state.firstName !== this.userComparator.firstName) || (this.state.lastName !== this.userComparator.lastName) || (this.state.email !== this.userComparator.email)) {
            return true;
        } else {
            return false;
        }
    }

    confirmDiscard() {
        console.log('running EditUser.confirmDiscard().');
        window.confirm("Are you sure you want to go back? Any unsaved changes will be lost.") && this.props.history.goBack()
    }

    toggle() {

        this.setState({
            popupIsOpen: !this.state.popupIsOpen
        })

    }


    render() {
        // const { errors } = this.state;
        // const user = this.props.location.state.user;        

        console.log('Running EditUser.render().');

        return (
            <div>
                <Helmet bodyAttributes={{ style: "background-color: #000000" }}>
                    <title>{"Jake Whitesell – Edit User"}</title>
                </Helmet>
                {this.props.collapseButtonIsShowing ?
                    <NavigationBarMobile />
                    :
                    <NavigationBarDesktop />
                }
                <div className="container">

                    <div style={{ height: "128px" }}></div>

                    <div className="row">
                        {/* <div className="col-lg-4"></div> */}
                        <div className="col-lg-12">
                            <Button color="primary" /*outline*/ onClick={e => this.fieldsHaveChanged() ? this.confirmDiscard() : this.props.history.goBack()}>
                                Back
                            </Button>
                        </div>
                        {/* <div className="col-lg-4"></div> */}
                    </div>

                    <div className="row">
                        {/* <div className="col-lg-4"></div> */}
                        <div className="col-lg-12">

                            {/* <Button color="primary" outline onClick={e => this.fieldsHaveChanged() ? this.confirmDiscard() : this.props.history.goBack()}>
                            Back
                        </Button> */}

                            <div className="row">
                                <div className="col-lg-12">
                                    <p className="customHeader" style={{ textAlign: 'center' }}>
                                        Edit User
                                    </p>
                                </div>
                            </div>

                            <form noValidate onSubmit={this.onSubmit} id="editUserForm">

                                <div className="form-row">
                                    <div className="form-group col-md-4" style={{ paddingBottom: '15px' }}>
                                        <label style={{ color: "white" }} htmlFor="firstName">First Name</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.firstName}
                                            // error={errors.firstName}
                                            id="firstName"
                                            type="text"
                                            // className={classnames("form-control", {
                                            //     // invalid: errors.firstName
                                            // })}
                                            className="form-control"
                                        />
                                    </div>

                                    <div className="form-group col-md-4" style={{ paddingBottom: '15px' }}>
                                        <label style={{ color: "white" }} htmlFor="lastName">Last Name</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.lastName}
                                            // error={errors.lastName}
                                            id="lastName"
                                            type="text"
                                            // className={classnames("form-control", {
                                            //     invalid: errors.lastName
                                            // })}
                                            className="form-control"
                                        />
                                    </div>

                                    <div className="form-group col-md-4" style={{ paddingBottom: '15px' }}>
                                        <label style={{ color: "white" }} htmlFor="email">Email</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.email}
                                            // error={errors.email}
                                            id="email"
                                            type="email"
                                            // className={classnames("form-control", {
                                            //     invalid: errors.email
                                            // })}
                                            className="form-control"
                                        />
                                    </div>

                                </div>
                            </form>
                        </div>
                        {/* <div className="col-lg-4"></div> */}
                    </div>

                    <div style={{ height: '5vh' }}></div>

                    <div className="row">
                        {/* <div className="col-lg-4"></div> */}
                        <div className="col-lg-12">
                            <Button type="submit" form="editUserForm" color="primary" /*outline*/ className="float-right">
                                Save
                        </Button>
                        </div>
                        {/* <div className="col-lg-4"></div> */}
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

const mapSizesToProps = ({ width }) => ({
    collapseButtonIsShowing: width < 1200,
    width: width
})

export default connect(mapStateToProps, { updateUser, updateCurrentUser })(withRouter(withSizes(mapSizesToProps)(EditUser)));