import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import axios from 'axios';
import withSizes from 'react-sizes'
import EventDataTable from './EventDataTable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { decrementProgress, incrementProgress } from '../../actions/progress';
import { deleteEvent, addEvent } from "../../actions/adminActions";
import Footer from "../layout/Footer";

import NavigationBarMobile from '../layout/NavigationBarMobile'
import NavigationBarDesktop from '../layout/NavigationBarDesktop'

import { Nav } from 'reactstrap';

class ViewEvents extends Component {

    constructor(props) {
        super(props);

        console.log('\n\nViewEvents.constructor called.');
        console.log('value of this.props.auth.user:');
        console.log(this.props.auth.user);

        // bound functions
        this.deleteEventFunction = this.deleteEventFunction.bind(this);
        this.editEventFunction = this.editEventFunction.bind(this);
        this.duplicateEventFunction = this.duplicateEventFunction.bind(this);

        this.state = { eventsCollection: [] };

    }

    componentDidMount() {
        console.log('ViewEvents.componentDidMount called.')

        // axios.get('http://localhost:5000/api/courses')
        const url = global.serverAddress + '/api/events'

        axios.get(url)
            .then(res => {
                console.log("\n\nDEBUG:")
                console.log("value of res.data:")
                console.log(res.data)
                this.setState({ eventsCollection: res.data });
            })
            .catch(function (error) {
                console.log(error);
            })
    }


    editEventFunction(id) {
        console.log('running ViewGroups.editEventFunction; value of id passed: ' + id);
        console.log('running ViewGroups.editEventFunction; value of this.props.history: ' + this.props.history);

        const url = global.serverAddress + '/api/events/edit/' + id;

        axios.get(url)
            .then(res => {
                console.log('reached second then block; value of res: ');
                console.log(res);
                this.props.history.push({
                    pathname: '/admin-panel/edit-event',
                    search: '?query=abc',
                    state: { event: res.data }
                })
            })
            .catch(function (error) {
                console.log('reached catch block.');
                console.log(error);
            })
    }

    dataTable(mode) {
        return this.state.eventsCollection.map((data, i) => {
            console.log('creating data table for event number ' + i)
            return <EventDataTable obj={data} key={i} mode={mode} deleteEventFunction={this.deleteEventFunction} editEventFunction={this.editEventFunction} duplicateEventFunction={this.duplicateEventFunction} />;
        });
    }

    deleteEventFunction(id) {

        console.log('ViewEvents.deleteEventFunction called.');
        console.log('value of this.props.deleteEvent: ' + this.props.deleteEvent);
        console.log('value of id: ' + id);
        this.props.deleteEvent(id, this.updateState);

    }

    duplicateEventFunction(id) {

        console.log("ViewEvents.duplicateEventFunction called.")

        const url = global.serverAddress + '/api/events/edit/' + id;

        const that = this

        axios.get(url)
            .then(res => {
                console.log('reached second then block; value of res: ');
                console.log(res);

                const duplicatedEventData = {
                    group: res.data.group,
                    groupLink: res.data.groupLink,
                    venue: res.data.venue,
                    venueLink: res.data.venueLink,
                    location: res.data.location,
                    title: res.data.title,
                    description: res.data.description,
                    startDate: res.data.startDate,
                    timeText: res.data.timeText,
                    ticketsLink: res.data.ticketsLink
                };

                // this.props.addEvent(duplicatedEventData, function () {
                //     that.componentDidMount()
                // });

                this.props.addEvent(duplicatedEventData, this.props.history, function () {
                    that.componentDidMount()
                });

            })
            .catch(function (error) {
                console.log('reached catch block.');
                console.log(error);
            })


    }


    updateState = (newState) => {
        console.log('running ViewEvents.updateState.');

        this.componentDidMount();
    }

    render() {
        console.log('running ViewEvents.render; value of this.props.history: ' + this.props.history);
        return (
            <div className="wrapper-events">
                <Helmet bodyAttributes={{ style: "background-color: #000000" }}>
                    <title>{"Jake Whitesell – View Events"}</title>
                </Helmet>
                {this.props.collapseButtonIsShowing ?
                    <NavigationBarMobile />
                    :
                    <NavigationBarDesktop />
                }
                <div className="container-fluid">

                    <div style={{ height: "128px" }}></div>

                    <div className="row">
                        <div className="col-lg-12">
                            <p className="customHeader" style={{ textAlign: 'center' }}>
                                Events
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="float-left">
                                <Link to="/admin-panel">
                                    Back
                                </Link>
                            </div>

                            <div className="float-right">
                                <Link to="/admin-panel/add-event">
                                    Add Event
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div style={{ height: "32px" }}></div>

                    {/* <div className="row"> */}
                    {/* <div className="col-md-12"> */}
                    <table className="table table-striped">
                        <thead className="thead-dark">
                            <tr style={{ color: "white" }}>
                                {/* <td>ID</td> */}
                                <td>Group</td>
                                <td>Group Link</td>
                                <td>Venue</td>
                                <td>Venue Link</td>
                                <td>Location</td>
                                <td>Title</td>
                                <td>Description</td>
                                <td>Start Date</td>
                                <td>Time Text</td>
                                <td>Tickets link</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {this.dataTable(0)}
                        </tbody>
                    </table>
                    {/* </div> */}
                    {/* </div> */}
                </div>
                <Footer />
            </div>
        )
    }
}

ViewEvents.propTypes = {
    deleteEvent: PropTypes.func.isRequired,
    editEvent: PropTypes.func.isRequired,
    addEvent: PropTypes.func.isRequired,
    incrementProgress: PropTypes.func.isRequired,
    decrementProgress: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth
});

const mapSizesToProps = ({ width }) => ({
    collapseButtonIsShowing: width < 1200,
    width: width
})

export default connect(mapStateToProps, { deleteEvent, addEvent, incrementProgress, decrementProgress, withRouter })(withSizes(mapSizesToProps)(ViewEvents));
