
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button } from 'reactstrap';
import Helmet from 'react-helmet';
import withSizes from 'react-sizes'
// import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerUser } from "../../actions/authActions";
import classnames from "classnames";
import Footer from "../layout/Footer";

import NavigationBarMobile from '../layout/NavigationBarMobile'
import NavigationBarDesktop from '../layout/NavigationBarDesktop'

class Register extends Component {
    constructor() {
        super();
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            courses: [],
            password: "",
            password2: "",
            errors: {}
        };
    }

    componentDidMount() {
        // If logged in and user navigates to Register page, should redirect them to dashboard
        if (this.props.auth.isAuthenticated) {
            this.props.history.push("/dashboard");
        }

        console.log("componentDidMount called.")
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSubmit = e => {
        e.preventDefault();

        const errors = this.state.errors;

        console.log("errors: " + errors);

        const newUser = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            courses: this.state.courses,
            roles: ['user'],
            password: this.state.password,
            password2: this.state.password2
        };

        this.props.registerUser(newUser, this.props.history);
    };

    render() {
        const { errors } = this.state;

        return (
            <div>
                <Helmet bodyAttributes={{ style: "background-color: #000000" }}>
                    <title>{"Jake Whitesell – Register"}</title>
                </Helmet>
                {this.props.collapseButtonIsShowing ?
                    <NavigationBarMobile />
                    :
                    <NavigationBarDesktop />
                }

                <div className="container">

                    <div style={{ height: "128px" }}></div>

                    <div className="row">

                        <div className="col-lg-4"></div>

                        <div className="col-lg-4">
                            <Link to="/">
                                Back to home
                            </Link>

                            <div style={{ paddingTop: '30px' }}></div>

                            <div className="row">
                                <div className="col-lg-12">
                                    <p className="customHeader" style={{ textAlign: 'center' }}>
                                        Register
                                    </p>
                                </div>
                            </div>
                            <p style={{ textAlign: 'center', paddingBottom: '15px', color: "white" }}>
                                Already have an account? <Link to="/login">Log in</Link>
                            </p>

                        </div>

                        <div className="col-lg-4"></div>

                    </div>

                    <div className="row">

                        <div className="col-lg-4"></div>

                        <div className="col-lg-4">

                            <form noValidate onSubmit={this.onSubmit}>

                                <div className="form-group" style={{ paddingBottom: '15px' }}>
                                    <label style={{ color: "white" }} htmlFor="firstName">First Name</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.firstName}
                                        error={errors.firstName}
                                        id="firstName"
                                        type="text"
                                        className={classnames("form-control", {
                                            invalid: errors.firstName
                                        })}
                                    />
                                    <p className="text-danger float-right">{errors.firstName}</p>

                                    {/* <span><br /><p className="text-danger">{errors.firstName}</p></span> */}

                                </div>

                                <div className="form-group" style={{ paddingBottom: '15px' }}>
                                    <label style={{ color: "white" }} htmlFor="lastName">Last Name</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.lastName}
                                        error={errors.lastName}
                                        id="lastName"
                                        type="text"
                                        className={classnames("form-control", {
                                            invalid: errors.lastName
                                        })}
                                    />
                                    <p className="text-danger float-right">{errors.lastName}</p>
                                    {/* <span className="red-text"><br />{errors.lastName}</span> */}
                                </div>

                                <div className="form-group" style={{ paddingBottom: '15px' }}>
                                    <label style={{ color: "white" }} htmlFor="email">Email</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.email}
                                        error={errors.email}
                                        id="email"
                                        type="email"
                                        className={classnames("form-control", {
                                            invalid: errors.email
                                        })}
                                    />
                                    <p className="text-danger float-right">{errors.email}</p>
                                    {/* <span className="red-text"><br />{errors.email}</span> */}
                                </div>

                                <div className="form-group" style={{ paddingBottom: '15px' }}>
                                    <label style={{ color: "white" }} htmlFor="password">Password</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.password}
                                        error={errors.password}
                                        id="password"
                                        type="password"
                                        className={classnames("form-control", {
                                            invalid: errors.password
                                        })}
                                    />
                                    <p className="text-danger float-right">{errors.password}</p>
                                    {/* <span className="red-text"><br />{errors.password}</span> */}
                                </div>

                                <div className="form-group" style={{ paddingBottom: '15px' }}>
                                    <label style={{ color: "white" }} htmlFor="password2">Confirm Password</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.password2}
                                        error={errors.password2}
                                        id="password2"
                                        type="password"
                                        className={classnames("form-control", {
                                            invalid: errors.password2
                                        })}
                                    />
                                    <p className="text-danger float-right">{errors.password2}</p>
                                    {/* <span className="red-text"><br />{errors.password2}</span> */}
                                </div>

                                <Button type="submit" color="primary" /*outline*/ className="float-right">
                                    Register
                        </Button>

                            </form>
                        </div>
                        <div className="col-lg-4"></div>
                    </div>
                    <Footer />
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

const mapSizesToProps = ({ width }) => ({
    collapseButtonIsShowing: width < 1200,
    width: width
})

export default connect(mapStateToProps, { registerUser })(withRouter(withSizes(mapSizesToProps)(Register)));