import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import AdminRoute from "./AdminRoute";

const mapStateToProps = (state) => {
    return {
        jwtTokens: state.auth.jwtTokens,
        email: state.auth.email,
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminRoute);
