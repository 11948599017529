import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import withSizes from 'react-sizes'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import Footer from "../layout/Footer";
import NavigationBarMobile from '../layout/NavigationBarMobile'
import NavigationBarDesktop from '../layout/NavigationBarDesktop'

class AdminPanel extends Component {
  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };

  render() {
    console.log('running AdminPanel.render; value of this.props.history: ' + this.props.history);

    const { user } = this.props.auth;

    return (
      <div>
        <Helmet bodyAttributes={{ style: "background-color: #000000" }}>
          <title>{"Jake Whitesell – Admin Panel"}</title>
        </Helmet>
        {this.props.collapseButtonIsShowing ?
          <NavigationBarMobile />
          :
          <NavigationBarDesktop />
        }

        <div className="container">

          <div style={{height: "128px"}}></div>

          <div className="row">
            <div className="col-lg-12">
              <p className="customHeader" style={{ textAlign: 'center' }}>
                Admin Panel
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12" style={{ color: "white" }}>
              <b>First Name:</b> {user.firstName}
              <br />
              <b>Last Name:</b> {user.lastName}
              <br />
              <b>Email:</b> {user.email}
              <br />
              <b>Roles:</b> {user.roles.join(", ")}
              <br />
              <b>ID:</b> {user.id}
            </div>
          </div>

          {/* <div className="row" style={{height: '5vh'}}></div> */}

          {/* <div className="row">
          <div className="col-12-md">
            <Link to="/admin-panel/add-course">
              Add Course
            </Link>
          </div>
        </div> */}

          <div className="row" style={{ height: '2vh' }}></div>

          <div className="row">
            <div className="col-md-12">
              <Link to="/admin-panel/view-users">
                View Users
            </Link>
            </div>
          </div>

          <div className="row" style={{ height: '2vh' }}></div>

          <div className="row">
            <div className="col-md-12">
              <Link to="/admin-panel/view-groups">
                View Groups
            </Link>
            </div>
          </div>

          <div className="row" style={{ height: '2vh' }}></div>

          <div className="row">
            <div className="col-md-12">
              <Link to="/admin-panel/view-events">
                View Events
            </Link>
            </div>
          </div>


          <Footer />
        </div>
      </div>
    );
  }
}

AdminPanel.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

const mapSizesToProps = ({ width }) => ({
  collapseButtonIsShowing: width < 1200,
  width: width
})

export default connect(mapStateToProps, { logoutUser })(withSizes(mapSizesToProps)(AdminPanel));

