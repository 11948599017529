
import React, { Component, /*useRef*/ } from "react";
import { /*Link,*/ withRouter } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Helmet from 'react-helmet';
import withSizes from 'react-sizes'
// import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateGroup } from "../../actions/adminActions";
// import classnames from "classnames";
import Footer from "../layout/Footer";

import NavigationBarMobile from '../layout/NavigationBarMobile'
import NavigationBarDesktop from '../layout/NavigationBarDesktop'

import UserDataTable from "./UserDataTable";
// import { unregisterUserForCourse } from "../../actions/adminActions";
// import { registerUserForCourse } from "../../actions/adminActions";
import { updateCurrentUser } from '../../actions/authActions';
import { updateUser } from '../../actions/adminActions';
import axios from 'axios';
import MultiImageInput from 'react-multiple-image-input';


class EditGroup extends Component {

    constructor(props) {
        super(props);
        const group = this.props.location.state.group;

        // this.unregisterUserFunction = this.unregisterUserFunction.bind(this);
        // this.registerUserFunction = this.registerUserFunction.bind(this);
        this.updateState = this.updateState.bind(this);
        this.toggle = this.toggle.bind(this);
        this.fieldsHaveChanged = this.fieldsHaveChanged.bind(this);

        this.state = {
            group: {
                name: group.name,
                description: group.description,
                images: group.images,
                id: group._id,
            },

            errors: {},

            // usersCollection: [],
            popupIsOpen: false,
            // didFetchUsers: false
        };
        console.log(this.props);

        this.groupComparator = {};

    }


    componentDidMount() {
        this.groupComparator = {
            name: this.state.group.name,
            description: this.state.group.description,
            images: this.state.group.images,
        }

        // console.log('\n\nDEBUG: value of this.courseComparator at end of componentDidMount:');
        console.log(this.groupComparator);
    }

    onChange({ target: { id, value } }, type) {
        this.setState({
            group: {
                ...this.state.group,
                [id]: value
            }
        });
    }




    // onChange = e => {
    //     console.log('EditCourse.onChange called; values passed:');
    //     console.log('[e.target.id] ' + [e.target.id]);
    //     console.log('e.target.value ' + e.target.value);

    //     this.setState({ [e.target.id]: e.target.value });        

    // };

    onSubmit = e => {
        console.log('running onSubmit.');

        console.log("value of this.state.group:")
        console.log(this.state.group)

        e.preventDefault();

        const errors = this.state.errors;

        this.props.updateGroup(this.groupComparator, this.state.group, this.props.history);

    };

    fieldsHaveChanged() {

        if ((this.state.group.name !== this.groupComparator.name) || (this.state.group.description !== this.groupComparator.description) || (this.state.group.images !== this.groupComparator.images)) {
            return true;
        } else {
            return false;
        }
    }

    confirmDiscard() {
        // console.log('running EditCourse.confirmDiscard().');
        window.confirm("Are you sure you want to go back? Any unsaved changes will be lost.") && this.props.history.goBack()
    }

    dataTable(collection, mode) {
        // return this.state.course.registrants.map((data, i) => {
        return collection.map((data, i) => {
            // console.log('creating data table for user number ' + i)
            return <UserDataTable mode={mode} obj={data} key={i} group={this.state.group} />;
        });
    }

    updateState(updatedUserData, updatedGroupData) {

        console.log('EditGroup.updateState called.');

        this.setState({
            name: updatedGroupData.name,
            description: updatedGroupData.description,
            images: updatedGroupData.images,
            id: updatedGroupData._id,
        })

        if (updatedUserData._id === this.props.auth.user.id) {
            // console.log('updatedUserData.id === this.props.auth.user.id; calling this.props.updateCurrentUser.');
            this.props.updateCurrentUser(updatedUserData)
        } else {
            // console.log('updatedUserData.id !== this.props.auth.user.id; values:');
            // console.log('updatedUserData.id: ' + updatedUserData.id);
            // console.log('this.props.auth.user.id: ' + this.props.auth.user.id);
        }

    }

    toggle() {

        this.setState({
            popupIsOpen: !this.state.popupIsOpen
        })

    }


    render() {
        // const { errors } = this.state;
        // const user = this.props.location.state.user;        

        console.log('Running EditGroup.render().');

        return (
            <div>
                <Helmet bodyAttributes={{ style: "background-color: #000000" }}>
                    <title>{"Jake Whitesell – Edit Group"}</title>
                </Helmet>
                {this.props.collapseButtonIsShowing ?
                    <NavigationBarMobile />
                    :
                    <NavigationBarDesktop />
                }

                <div className="container">

                    <div style={{height: "128px"}}></div>

                    <div className="row">
                        <div className="col-lg-12">
                            <Button color="primary" /*outline*/ onClick={e => this.fieldsHaveChanged() ? this.confirmDiscard() : this.props.history.goBack()}>
                                Back
                        </Button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">

                            <div className="row">
                                <div className="col-lg-12">
                                    <p className="customHeader" style={{ textAlign: 'center' }}>
                                        Edit Group
                                    </p>
                                </div>
                            </div>

                            <form noValidate onSubmit={this.onSubmit} id="editGroupForm">

                                <div className="form-group" style={{ paddingBottom: '15px' }}>
                                    <label style={{ color: "white" }} htmlFor="name">Name</label>
                                    <input
                                        onChange={e => this.onChange(e, 'name')}
                                        value={this.state.group.name}
                                        // error={errors.title}
                                        id="name"
                                        type="name"
                                        // className={classnames("form-control", {
                                        //     invalid: errors.title
                                        // })}
                                        className="form-control"

                                    />
                                </div>

                                <div className="form-group" style={{ paddingBottom: '15px' }}>
                                    <label style={{ color: "white" }} htmlFor="description">Description</label>
                                    <textarea
                                        onChange={e => this.onChange(e, 'description')}
                                        value={this.state.group.description}
                                        // error={errors.description}
                                        id="description"
                                        type="description"
                                        // className={classnames("form-control", {
                                        //     invalid: errors.description
                                        // })}
                                        className="form-control"
                                        style={{ height: '30vh' }}
                                    />
                                </div>

                                <div className="form-group">
                                    <div>
                                        <MultiImageInput
                                            images={this.state.group.images}
                                            // setImages={value => this.setState({
                                            //     images: value
                                            // })}
                                            setImages={value => this.setState(prevState => ({
                                                group: {
                                                    ...prevState.group,
                                                    images: value
                                                }
                                            }))}
                                            // cropConfig={{ crop, ruleOfThirds: true }}
                                            allowCrop={false}
                                            max={9999}
                                        />
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>

                    <div style={{ height: '5vh' }}></div>

                    <div className="row">
                        <div className="col-lg-12">
                            <Button type="submit" form="editGroupForm" color="primary" /*outline*/ className="float-right">
                                Save
                        </Button>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

const mapSizesToProps = ({ width }) => ({
    collapseButtonIsShowing: width < 1200,
    width: width
})

export default connect(mapStateToProps, { updateGroup, updateUser, updateCurrentUser })(withRouter(withSizes(mapSizesToProps)(EditGroup)));