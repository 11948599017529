import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button } from 'reactstrap';
import Helmet from 'react-helmet';
import withSizes from 'react-sizes'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { resetPassword } from "../../actions/authActions";
import classnames from "classnames";
// import axios from "axios";
import Footer from "../layout/Footer";

import NavigationBarMobile from '../layout/NavigationBarMobile'
import NavigationBarDesktop from '../layout/NavigationBarDesktop'


class ForgotPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            errors: {},
            success: false
        };
        console.log(this.props);
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSubmit = e => {
        e.preventDefault();

        // this.props.loginUser(userData);

        const emailData = {
            email: this.state.email
        };


        const that = this;

        console.log('DEBUG: value of this.updateState: ' + this.updateState);
        this.props.resetPassword(emailData, function (result) {
            console.log('running resetPassword completion handler; value passed: ' + result);
            that.setState({
                success: result
            });
            console.log('new value of this.state:');
            console.log(that.state);
        });

    };


    renderForm() {

        const errors = this.state.errors;

        if (!this.state.success) {
            return (
                <div>

                    <div style={{height: "128px"}}></div>

                    <div className="row">
                        <div className="col-lg-12">
                            <p className="customHeader" style={{ textAlign: 'center' }}>
                                Reset Your Password
                            </p>
                        </div>
                    </div>

                    <form noValidate onSubmit={this.onSubmit}>

                        <input
                            onChange={this.onChange}
                            value={this.state.email}
                            error={errors.email}
                            id="email"
                            type="email"
                            className={classnames("form-control", {
                                invalid: errors.email || errors.emailnotfound
                            })}
                        />
                        <label style={{ color: "white" }} htmlFor="email">Email</label>
                        <span>
                            <p style={{ color: 'red' }}>
                                {errors.email}
                            </p>
                        </span>

                        <Button type="submit" color="primary" /*outline*/ className="float-right">
                            Submit
                        </Button>
                    </form>
                </div>
            )
        } else {
            return (
                <div>
                    <h4 style={{ textAlign: 'center', paddingBottom: '15px' }}>
                        Email sent.
                    </h4>
                </div>
            )
        }
    }

    render() {
        const { errors } = this.state;

        return (
            <div>

                <Helmet bodyAttributes={{ style: "background-color: #000000" }}>
                    <title>{"Jake Whitesell – Forgot Password"}</title>
                </Helmet>
                {this.props.collapseButtonIsShowing ?
                    <NavigationBarMobile />
                    :
                    <NavigationBarDesktop />
                }

                <div className="container">

                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4">

                            <div style={{ paddingBottom: '30px' }}>
                                <Link to="/">
                                    Back to Home
                                </Link>
                            </div>

                            {this.renderForm()}

                        </div>
                        <div className="col-md-4"></div>
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}

ForgotPassword.propTypes = {
    resetPassword: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

const mapSizesToProps = ({ width }) => ({
    collapseButtonIsShowing: width < 1200,
    width: width
})

export default connect(mapStateToProps, { resetPassword })(withSizes(mapSizesToProps)(ForgotPassword));
